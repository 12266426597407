/* eslint-disable @typescript-eslint/no-explicit-any */
import { createPopper } from '@popperjs/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg';
import { useOutsideAlerter } from '../../hooks';


interface SelectProps {
    value: any,
    options: { label?: string, value: any }[],
    minimized?: boolean,
    radio?: boolean,
    nobd?: boolean,
    top?: boolean,
    nosearch?: boolean,
    placeholder?: string,
    className?: string,
    onChange?: (value: any, revert: (v: any) => void) => void,
    disabled?: boolean,
    error?: string,
    dataTestId?: string,
    children?: React.ReactNode,
}

function Select({ value, options, onChange, placeholder, minimized, radio, className, nosearch, nobd, top, disabled, error, dataTestId, children }: SelectProps) {
    const refDiv = useRef<HTMLDivElement | null>(null);

    const [label, setLabel] = useState<string>();
    const [val, setVal] = useState<string>(value);
    const [isExpand, setIsExpand] = useState(false);
    const [filter, setFilter] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    const setLocalVal = useCallback((v: any) => {
        if (options.length > 0) {
            if (v === undefined) {
                setVal('');
                setLabel(undefined);
            } else {
                const l = options.find(o => o.value === v)?.label;
                if (l) {
                    setVal(v);
                    setLabel(l);
                } else {
                    setVal('');
                    setLabel(undefined);
                }
            }
        }
    }, [options]);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const wrapperRef = useRef(null);
    useOutsideAlerter(minimized ? refDiv : wrapperRef, setIsExpand);

    useEffect(() => {
        if (!isExpand) {
            setFilter('');
        } else {
            inputRef.current?.focus();
        }
    }, [isExpand]);

    useEffect(() => {
        if (refDiv.current && wrapperRef.current) {
            createPopper(wrapperRef.current, refDiv.current, {
                placement: 'bottom-start',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 4],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['top-start', 'top-end'],
                        },
                    },
                ],
            });
        }
    }, [isExpand]);

    useEffect(() => {
        setFilteredOptions(options
            .filter(o => o.label) // фильтруем пустые строки
            .filter(o => !filter || o.label?.toLowerCase().includes(filter.toLowerCase())));
    }, [filter, options]);

    useEffect(() => {
        if (options.length > 0) {
            if (value === undefined) {
                setVal('');
                setLabel(undefined);
                // const option = options.at(0);
                // if (option) {
                //     setLabel(option.label);
                //     setVal(option.value);
                // }
            } else {
                const l = options.find(o => o.value === value)?.label;
                if (l) {
                    setVal(value);
                    setLabel(l);
                } else {
                    setVal('');
                    setLabel(undefined);
                }
            }
        }
    }, [options, value]);

    return (
        <div className={className || 'form__item'} ref={wrapperRef}>
            <div className={'comboInput combo-select _single' + (radio ? ' _radio' : '') + (isExpand ? ' _active' : '') + ((val !== undefined && val !== '') ? ' _selected' : '')
                + (minimized ? ' _min' : '') + (nobd ? ' _nobd' : '') + (top ? ' _top' : '')}
                data-test-id={dataTestId}
                onClick={() => {
                    if (disabled) return;
                    setIsExpand(!isExpand);
                }}
                style={{ borderColor: error ? 'var(--c-red)' : undefined }}>
                <span className="comboInput__label">
                    {label || (!isExpand && placeholder)}
                    {!nosearch &&
                        <span className="comboInput__search" style={{ display: 'inline-block' }}>
                            <input ref={inputRef} value={filter} type="text" size={1} onChange={(e) => setFilter(e.target.value)} />
                        </span>
                    }
                </span>
                <span className="comboInput__icon">
                    <ArrowDown />
                </span>
                {isExpand && !minimized &&
                    <div
                        className={'comboInput__list _single'}
                    >
                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                className={'comboInput__list-item' + (option.value === val ? ' _active' : '')}
                                onClick={() => {
                                    setVal(option.value);
                                    setLabel(option.label);
                                    onChange && onChange(option.value, (v) => setLocalVal(v));
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                }
                {isExpand && minimized && ReactDOM.createPortal(
                    <div
                        ref={refDiv}
                        className={'comboInput__list _single _min'}
                        style={{
                            display: 'block',
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            margin: '0px',
                        }}
                    >
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={'comboInput__list-item' + (option.value === val ? ' _active' : '')}
                                onClick={() => {
                                    setVal(option.value);
                                    setLabel(option.label);
                                    onChange && onChange(option.value, (v) => setLocalVal(v));
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                    , document.body)}
            </div>
            {children}
            {error && <div className="form__input-error-message">{error}</div>}
        </div>
    );
}

export default Select;
