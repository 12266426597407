const workercode = () => {
    let baseURL = '';
    let lastEtag = '';
    async function checkEtag() {
        try {
            const response = await fetch(baseURL + '?timestamp=' + new Date().getTime(), {
                method: 'HEAD',
            });
            const etag = response.headers.get('etag') || '';
            if (lastEtag) {
                if (lastEtag !== etag) {
                    self.postMessage('CHANGE');
                }
            } else {
                lastEtag = etag;
            }
        } catch (e) {
            console.warn(e);
        }
    }

    self.onmessage = function (e) {
        baseURL = e.data;
        checkEtag();
        self.setInterval(checkEtag, 60000);
    }
};

let code = workercode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const worker_script = URL.createObjectURL(blob);

export default worker_script;