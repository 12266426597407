import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Highlighter from 'react-highlight-words';
import { Trans, useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomMenuItem } from './App';
import { ReactComponent as At } from './assets/img/icons/at.svg';
import { ReactComponent as Hash } from './assets/img/icons/hash.svg';
import { ReactComponent as AccountList } from './assets/img/icons/user-settings-accounts.svg';
import { ReactComponent as UserSettingsInfo } from './assets/img/icons/user-settings-info.svg';
import { ReactComponent as UserSettingsName } from './assets/img/icons/user-settings-name.svg';
import { ReactComponent as LogoFull } from './assets/logo_blue.svg';
import { ReactComponent as LogoB } from './assets/logo_m_blue.svg';
import { ReactComponent as LogoW } from './assets/logo_m_white.svg';
import { ReactComponent as LogoFullWhite } from './assets/logo_white.svg';
import { ReactComponent as Burger } from './assets/svg/Burger.svg';
import { ReactComponent as HeadingCatalogProducer } from './assets/svg/Catalog.svg';
import { ReactComponent as Close } from './assets/svg/CloseSearch.svg';
import { ReactComponent as Lion } from './assets/svg/Lion.svg';
import { ReactComponent as Notify } from './assets/svg/Notify.svg';
import { ReactComponent as HeadIcon } from './assets/svg/PermRequest.svg';
import { ReactComponent as Plus } from './assets/svg/Plus.svg';
import { ReactComponent as Search } from './assets/svg/Search.svg';
import { ReactComponent as Unicorn } from './assets/svg/Unicorn.svg';
import { ReactComponent as User } from './assets/svg/User.svg';
import { ReactComponent as Producer } from './assets/svg/header-producer.svg';
import { ReactComponent as HeadingCatalog } from './assets/svg/heading-catalog.svg';
import { ReactComponent as Help } from './assets/svg/help.svg';
import { ReactComponent as UserList } from './assets/svg/list-users.svg';
import { ReactComponent as Logout } from './assets/svg/logout.svg';
import { ROLE, useIsAdmin, useIsConsumer, useIsProvider } from './components/common';
import Button from './components/mplat/Button/Button';
import TableListName from './components/mplat/DataTable/TableListName';
import Header1 from './components/mplat/Header1';
import { Modal } from './components/mplat/Modal';
import { FeedbackModal } from './components/mplat/Modals/FeedbackModal';
import { useAcc, useAuth, useLanguage, useRole } from './hooks';
import { NotificationItem } from './pages/NotificationListPage';
import { useSuLogoutMutation } from './services/api';
import { ContentType, useLazyGetAccessRequestsQuery, useLazyGetContentListQuery, } from './services/content_v1';
import { useLazyGetNotificationsUserQuery } from './services/notifications';


function getUserIcon(role: ROLE, className?: string) {
    if ([ROLE.CONSUMER_ADMIN, ROLE.CONSUMER_AUDITOR, ROLE.CONSUMER_CONTENT_MANAGER, ROLE.CONSUMER_ENGINEER, ROLE.CONSUMER_OWNER].includes(role)) {
        return <User className={className} />;
    }
    if ([ROLE.PROVIDER_ADMIN, ROLE.PROVIDER_AUDITOR, ROLE.PROVIDER_CONTENT_MANAGER, ROLE.PROVIDER_ENGINEER, ROLE.PROVIDER_OWNER].includes(role)) {
        return <Producer className={className} />;
    }
    if ([ROLE.SITE_ADMIN, ROLE.SITE_AUDITOR, ROLE.SITE_MODERATOR].includes(role)) {
        return <Unicorn className={className} />;
    }
    if ([ROLE.PARTNER_MODERATOR_ADMIN, ROLE.PARTNER_MODERATOR_OWNER].includes(role)) {
        return <Lion className={className} />;
    }
    return <User className={className} />
}

interface AppHeaderProps {
    items: CustomMenuItem[]
}

function AppHeader({ items }: AppHeaderProps) {
    const [sulogout] = useSuLogoutMutation();
    const [searchContent, { data: findedContent }] = useLazyGetContentListQuery();
    const [searchAccess, { data: findedAccess }] = useLazyGetAccessRequestsQuery();
    const [searchNotify, { data: findedNotify }] = useLazyGetNotificationsUserQuery();

    const auth = useAuth();
    const role = useRole();
    const acc = useAcc();
    const { i18n } = useTranslation();
    const lang = useLanguage();
    const location = useLocation();
    const navigate = useNavigate();

    const isAdmin = useIsAdmin();
    const isConsumer = useIsConsumer();
    const isProvider = useIsProvider();

    const refSearchClose = useRef<HTMLDivElement | null>(null);
    const refSearch = useRef<HTMLDivElement | null>(null);
    const refSearchInput = useRef<HTMLInputElement | null>(null);
    const [searchMode, setSearchMode] = useState(false);
    const [realSearchMode, setRealSearchMode] = useState(false);
    const [searchPosition, setSearchPosition] = useState({ x: 0, y: 0, w: 0, t: 0 });
    const [accessRequested, setAccessRequested] = useState(false);
    const [search, setSearch] = useState('');
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (auth.account && search) {
            searchContent({ offset: 0, limit: 5, search, header: 1 });
            searchAccess({ offset: 0, limit: 5, search, header: 1 });
            searchNotify({ offset: 0, limit: 5, search, header: 1 }); // header 1 для инвалидации кэша
        }
    }, [auth.user, auth.account, search, searchAccess, searchContent, searchNotify]);

    useEffect(() => {
        setSearchPosition((s) => ({ ...s, w: 0 }));
        if (searchMode) {
            setRealSearchMode(true);
            if (refSearch.current) {
                const pos = { x: 0, y: 0, w: 0, t: 0 };
                const item = refSearch.current;
                pos.x = item.offsetLeft + item.clientWidth - 14;
                pos.y = item.offsetTop + item.clientHeight / 2;
                pos.w = Math.sqrt(document.documentElement.clientWidth * document.documentElement.clientWidth + document.documentElement.clientHeight * document.documentElement.clientHeight);
                pos.t = pos.w / 3000;
                if (pos.t > 0.5) {
                    pos.t = 0.5;
                } else if (pos.t < 0.2) {
                    pos.t = 0.2;
                }
                setTimeout(() => {
                    setSearchPosition(pos);
                    refSearchInput.current?.focus();
                }, 1);
            }
        } else {
            setTimeout(() => {
                setSearch('');
                setRealSearchMode(false);
            }, 500);
            if (refSearch.current) {
                const pos = { x: 0, y: 0, w: 0, t: 0 };
                const item = refSearch.current;
                pos.x = item.offsetLeft + item.clientWidth - 14;
                pos.y = item.offsetTop + item.clientHeight / 2;
                pos.w = 0;  // Math.sqrt(document.documentElement.clientWidth * document.documentElement.clientWidth + document.documentElement.clientHeight * document.documentElement.clientHeight);
                pos.t = pos.w / 3000;
                if (pos.t > 0.5) {
                    pos.t = 0.5;
                } else if (pos.t < 0.2) {
                    pos.t = 0.2;
                }
                setSearchPosition(pos);
            }
        }
    }, [searchMode]);

    return (
        <header className="header">
            <FeedbackModal isOpen={accessRequested} onClose={() => setAccessRequested(false)} />
            <div className="header__inner">
                <div className="header__logo">
                    {auth.user && location.pathname !== '/' ?
                        <Link to="/">
                            {isConsumer ?
                                <LogoW style={{ width: '28px', height: '30px' }} />
                                :
                                <LogoB style={{ width: '28px', height: '30px' }} />
                            }
                        </Link>
                        :
                        (isConsumer ?
                            <LogoFullWhite style={{ width: '149px', height: '30px' }} />
                            :
                            <Link to="/">
                                <LogoFull style={{ width: '149px', height: '30px' }} />
                            </Link>
                        )
                    }
                </div>
                <nav className="header__nav">
                    {auth.user ?
                        <ul>
                            {items.map((item) => <li key={item.link}>
                                {
                                    <NavLink
                                        to={item.link}
                                        end={item.noend ? false : true}
                                        className={({ isActive }) =>
                                            isActive ? 'header__nav-link _active' : 'header__nav-link'
                                        }
                                    >
                                        {item.icon}
                                        {item.label} {item.data ? <span className="header__nav-link-bubble">{item.data}</span> : null}
                                    </NavLink>
                                }
                            </li>)}
                        </ul>
                        :
                        <ul>
                            <li>
                                <Link to="/help" className="header__nav-link">
                                    <Trans>
                                        Help
                                    </Trans>
                                </Link>
                            </li>
                            <li>
                                <a href="#feedback" className="header__nav-link" onClick={(e) => {
                                    e.preventDefault();
                                    setAccessRequested(true);
                                }}>
                                    <Trans>Feedback</Trans>
                                </a>
                            </li>
                        </ul>
                    }
                </nav>
                {!auth.user ?
                    <div className="header__lang">
                        <a href="#lang" onClick={(e) => {
                            e.preventDefault();
                            changeLanguage(lang === 'ru' ? 'en' : 'ru');
                        }}>{lang === 'ru' ? 'En' : 'Ru'}</a>
                    </div>
                    :
                    null
                }
                {auth.user ?
                    <div className="header__create">
                        <Modal isOpen={createDialogOpen} onClose={() => {
                            setCreateDialogOpen(false);
                        }}>
                            <Header1>
                                <Trans>
                                    Create a new card
                                </Trans>
                            </Header1>
                            <div className="form">
                                <div className="form__item" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button onClick={() => { setCreateDialogOpen(false); navigate('/content/media/create'); }}><Plus /> <Trans>Content unit</Trans></Button>
                                </div>
                                <div className="form__item" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button className="form__item" onClick={() => { setCreateDialogOpen(false); navigate('/content/tv/create'); }}><Plus /> <Trans>TV channel</Trans></Button>
                                </div>
                            </div>
                        </Modal>
                        {isProvider &&
                            <Button className="btn header__create-btn" data-test-id="createContentButton" onClick={() => {
                                if (acc.prefferedSourceTV && acc.prefferedSourceUnit) {
                                    setCreateDialogOpen(true);
                                } else if (acc.prefferedSourceTV) {
                                    navigate('/content/tv/create');
                                } else if (acc.prefferedSourceUnit) {
                                    navigate('/content/media/create');
                                } else {
                                    setCreateDialogOpen(true);
                                }
                            }}>

                                <Plus />
                                <span className="header__create-label">
                                    <Trans>
                                        Create
                                    </Trans>
                                </span>
                            </Button>
                        }
                    </div>
                    :
                    null
                }
                <div className="header__user">
                    {auth.user ? (
                        <>
                            {auth.account && (
                                <>
                                    <div ref={refSearch} className="header__user-item _search" onClick={() => setSearchMode(true)}>
                                        <Search className="header__user-item-icon" />
                                    </div>
                                    <div className="header__user-item _menu _user">
                                        <NavLink to="/account" className={({ isActive }) =>
                                            isActive ? 'header__nav-link _active' : ''
                                        }>
                                            {getUserIcon(role.label, 'header__user-item-icon')}
                                        </NavLink>
                                        <div className="header__user-menu">
                                            {role.name}<br />
                                            <strong>{auth.user}</strong>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="header__user-item _menu">
                                <Burger />
                                <div className="header__user-menu">
                                    <Link to="/help" className="header__user-menu-item"><Help /> <Trans>Support</Trans></Link>
                                    {/* <Link to="/" className="header__user-menu-item"><Stats /> Статистика</Link> */}
                                    {auth.account && (
                                        <>
                                            {/* <Link to="/account" className="header__user-menu-item">{getUserIcon(role.label)} <Trans>Account</Trans></Link> */}
                                            <Link to="/profile" className="header__user-menu-item"><UserSettingsInfo /> <Trans>account:USER</Trans></Link>
                                            <Link to="/select-accounts" className="header__user-menu-item"><At /> <Trans>My accounts</Trans></Link>
                                            <Link to="/users" className="header__user-menu-item"><UserList className="" /> <Trans>Users</Trans></Link>
                                            {isAdmin && <Link to="/accounts" className="header__user-menu-item"><AccountList /> <Trans>Accounts</Trans></Link>}
                                            {isAdmin && <Link to="/organizations" className="header__user-menu-item"><UserSettingsName /> <Trans>Organizations</Trans></Link>}
                                            {isAdmin && <Link to="/group" className="header__user-menu-item"><Hash /> <Trans>Groups</Trans></Link>}
                                        </>
                                    )}
                                    <Link to="/logout" className="header__user-menu-item _logout"><Logout /> <Trans>Exit</Trans></Link>
                                    {auth.suUser ?
                                        <span className="header__user-menu-item _logout" onClick={() => sulogout({ refresh: auth.suRefresh, username: auth.suUser }).unwrap().catch(() => navigate('/logout'))}>
                                            <Logout /> {auth.user}
                                        </span>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="header__user-item _menu">
                            <Burger />
                            <div className="header__user-menu">
                                <Link to="/help" className="header__user-menu-item"><Help /><Trans>Header menu support</Trans></Link>
                                <a href="#feedback" className="header__user-menu-item" onClick={(e) => {
                                    e.preventDefault();
                                    setAccessRequested(true);
                                }}>
                                    <Help /> <Trans>Feedback</Trans>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                {!auth.user ?
                    <div className="header_mid_hide" style={{ flex: 1, order: 1 }}>
                        <div className="header__lang2" style={{ float: 'right' }}>
                            <a href="#lang" onClick={(e) => {
                                e.preventDefault();
                                changeLanguage(lang === 'ru' ? 'en' : 'ru');
                            }}>{lang === 'ru' ? 'En' : 'Ru'}</a>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {createPortal(
                <div
                    className="header search"
                    style={{
                        clipPath: `circle(${searchPosition.w}px at ${searchPosition.x}px ${searchPosition.y}px)`,
                        transition: `clip-path ${searchPosition.t}s cubic-bezier(0.25, 1, 0.5, 1)`,
                        display: realSearchMode ? 'block' : 'none'
                    }}
                >
                    <div className="header__inner">
                        <div className="header__logo">
                            <Link to="/">
                                <LogoW style={{ width: '27.64px' }} />
                            </Link>
                        </div>
                        <div className="search__body">
                            <div className="search__input">
                                <input ref={refSearchInput} value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form__input" placeholder="Поиск по заявкам и контенту" />
                            </div>
                            <div className="search__body-icon">
                                <Search />
                            </div>
                            <div ref={refSearchClose} className="search__body-close" onClick={() => setSearchMode(false)}>
                                <Close />
                            </div>
                        </div>
                        <div className="header__user">
                            <Link to="/profile" className="header__user-item _user">
                                {isConsumer ?
                                    <User className="header__user-item-icon" />
                                    :
                                    <Producer className="header__user-item-icon" />
                                }
                            </Link>
                            <div className="header__user-item _menu">
                                <Burger className="header__user-item-icon" />
                                <div className="header__user-menu">
                                    <Link to="/help" className="header__user-menu-item"><Help /> <Trans>Support</Trans></Link>
                                    {/* <Link to="/" className="header__user-menu-item"><Stats /> Статистика</Link> */}
                                    <Link to="/logout" className="header__user-menu-item _logout" onClick={() => setSearchMode(false)}><Logout /> <Trans>Exit</Trans></Link>
                                    {auth.suUser ?
                                        <span className="header__user-menu-item _logout" onClick={() => sulogout({ refresh: auth.suRefresh, account: auth.account, username: auth.suUser }).unwrap().catch(() => navigate('/logout'))}>
                                            <Logout /> {auth.user}
                                        </span>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search__results" style={{ display: search ? 'block' : 'none' }}>
                        <div className="search__results-inner">
                            {(!findedAccess?.results.count && !findedContent?.results.count && !findedNotify?.results.count) ?
                                <div className="search__results-404" style={{ opacity: 1, transform: 'translate(0px)' }}>По запросу «{search}» ничего не&nbsp;найдено</div>
                                :
                                <>
                                    {!!findedAccess?.results.count &&
                                        <section className="search__results-section _orders" style={{ opacity: 1, transform: 'translate(0px)' }}>
                                            <h1 className="search__results-heading">
                                                <HeadIcon />
                                                Заявки
                                            </h1>
                                            {findedAccess?.results.data?.map(unit => {
                                                return (
                                                    isConsumer ?
                                                        <div key={unit.id} style={{ cursor: 'pointer' }} className="search__results-item" onClick={() => { navigate('/access'); setSearchMode(false) }}>
                                                            <strong>
                                                                <Highlighter
                                                                    highlightClassName="search__results-highlight"
                                                                    searchWords={[search]}
                                                                    autoEscape={true}
                                                                    highlightTag={'span'}
                                                                    textToHighlight={`${unit.consumer.owner.firstname} ${unit.consumer.owner.lastname}`}
                                                                />

                                                            </strong><br />
                                                            <Highlighter
                                                                highlightClassName="search__results-highlight"
                                                                searchWords={[search]}
                                                                autoEscape={true}
                                                                highlightTag={'span'}
                                                                textToHighlight={`${unit.consumer.owner.firstname} ${unit.consumer.owner.lastname}`}
                                                            />
                                                            &nbsp;· <a href={`tel:${unit.consumer?.owner.phone}`}>{unit.consumer?.owner.phone}</a> · <a href={`mailto:${unit.consumer.owner.email}`} className="link-underline">{unit.consumer.owner.email}</a>
                                                        </div>
                                                        :
                                                        <div key={unit.id} style={{ cursor: 'pointer' }} className="search__results-item" onClick={() => { navigate('/access'); setSearchMode(false) }}>
                                                            <strong>
                                                                <Highlighter
                                                                    highlightClassName="search__results-highlight"
                                                                    searchWords={[search]}
                                                                    autoEscape={true}
                                                                    highlightTag={'span'}
                                                                    textToHighlight={unit.consumer.name || ''}
                                                                />
                                                            </strong><br />
                                                            <Highlighter
                                                                highlightClassName="search__results-highlight"
                                                                searchWords={[search]}
                                                                autoEscape={true}
                                                                highlightTag={'span'}
                                                                textToHighlight={`${unit.consumer.owner.firstname} ${unit.consumer.owner.lastname}`}
                                                            />
                                                            &nbsp;· <a href={`tel:${unit.consumer?.owner.phone}`}>{unit.consumer.owner.phone}</a> · <a href={`mailto:${unit.consumer?.owner.email}`} className="link-underline">{unit.consumer?.owner.email}</a>
                                                        </div>
                                                );
                                            })}
                                        </section>}
                                    {!!findedContent?.results.count && <section className="search__results-section _catalog" style={{ opacity: 1, transform: 'translate(0px)' }}>
                                        <h1 className="search__results-heading">
                                            {isConsumer ?
                                                <><HeadingCatalog /><Trans>Showcase</Trans></>
                                                :
                                                <><HeadingCatalogProducer /><Trans>Catalog</Trans></>
                                            }
                                        </h1>
                                        {findedContent?.results.data.map(unit => {
                                            return (
                                                <div key={unit.id} className="search__results-item" onClick={() => setSearchMode(false)}>
                                                    <TableListName
                                                        url={unit.covers?.at(0)?.url}
                                                        title={
                                                            <Highlighter
                                                                highlightClassName="search__results-highlight"
                                                                searchWords={[search]}
                                                                autoEscape={true}
                                                                highlightTag={'span'}
                                                                textToHighlight={unit.title}
                                                            />}
                                                        link={isConsumer ? `/content/view/${unit.id}/` : `/content/${unit.ctype === ContentType.TV ? 'tv' : 'media'}/${unit.id}/`}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </section>}
                                    {!!findedNotify?.results.count && <section className="search__results-section _notifications" style={{ opacity: 1, transform: 'translate(0px)' }}>
                                        <h1 className="search__results-heading">
                                            <Notify /><Trans>Notifications</Trans>
                                        </h1>
                                        {findedNotify?.results.data.map(item => {
                                            return (
                                                <div key={item.id} className="search__results-item" style={{ cursor: 'pointer' }} onClick={() => { navigate('/notifications'); setSearchMode(false) }}>
                                                    <NotificationItem item={item} highlight={search} />
                                                </div>
                                            );
                                        })}
                                    </section>}
                                </>
                            }
                        </div>
                    </div>
                </div>, document.body)}
        </header>
    );
}

export default AppHeader;
