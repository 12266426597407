import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { AxiosProgressEvent } from 'axios';
import { ROLE } from '../components/common';
import { Language, OffsetLimit, StatusPagesResponse, StatusResponse } from './api';
import { baseQueryWithReauth } from './reauth';


export enum AccountResType {
    SITE = 0, // 'Владельцы сайта'
    PROVIDER = 1, // 'Поставщик'
    CONSUMER = 2, // 'Потребитель'
    PARTNER = 3, // 'Партнер'
    PARTNER_MODERATOR = 4, // 'Партнер модератор'
    PARTNER_TVSRC = 6, // 'Партнер источник'
    PARTNER_TVSRC_MDLG = 5, // 'Партнер источник Медиалогистика'
}

export enum AccessRequestStatus {
    // OPEN = 'open',
    // PENDING = 'pending',
    // GRANTED = 'granted',
    // REJECTED = 'rejected',
    // ARCHIVED = 'archived',
    OPEN = 0, // 'open'
    GRANTED = 1, // 'granted'
    REJECTED = 2, // 'rejected'
    PENDING = 3, // 'pending'
    CANCELED = 4, // 'canceled'
    REDIRECTED = 5, // 'redirected'
    COPIED = 6, // 'copied'
    CLOSED = 7, // 'closed'
    FORWARDED = 8, // 'forwarded'
    ARCHIVED = 100, // 'archived',
}

export interface AclGroup {
    id: number,
    group: string,
    accounts: AccountAvail[]
}

export interface AuthResponse {
    username: string
    access: string
    refresh: string
    pd_agreement: boolean,
    agr_offer: boolean,
    accounts?: AccountShort[],
    suUser?: string
    suRefresh?: string
}

export interface AccountListElement {
    id: number,
    rtype: number,
    name: string,
    nickname: string | null,
    org: OrgMinimal,
    owner: UserProfile,
    logo: string | null,
}

export interface AccountUnit {
    id: number,
    rtype: number,
    name: string,
    nickname: string | null,
    org: {
        id: number,
        name: string
    } | null,
    owner: UserProfile | null,
    logo: string | null,
    aclgroups: AclGroup[]
    is_favorites: boolean,
}

export interface AccountClientUnit extends AccountUnit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: { [key: string]: any },
    users?: UserProfileAccount[],
}

export interface AccountShort {
    id: number,
    rtype: number,
    name: string,
    logo: string | null
}

export interface AccountReg {
    rtype: number | null,
    name: string,
    agreement: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: any
}

export interface AccountAvail {
    id: number,
    rtype: number,
    name: string,
    nickname: string | null,
    org: OrgMinimal | null,
    owner: UserProfileMinimal | null,
    logo: string | null
}

export interface AccountLogin {
    id: number,
    rtype: number,
    name: string,
    nickname: string | null,
    logo: string | null,
    orgname: string | null,
    nickid: string | null,
    roles: {
        id: number,
        name: string
    }[]
}

export interface ApproveRequest {
    id?: number,
    org: {
        id?: number,
        ctype?: number | null,
        name?: string,
    },
    account: {
        rtype?: number | string,
        name: string,
        email: string,
        nickname: string,
        logo: string | null
    },
    user: {
        username: string,
        email: string,
        lang?: Language,
        hide_fio?: boolean,
        hide_phone?: boolean,
        hide_email?: boolean,
    }
}

export interface OrgMinimal {
    id: number,
    name: string,
    taxnum: string,
}

export interface OrganizationShort extends OrgMinimal {
    ctype: number,
    legalnm: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details: any
}

export interface Organization extends OrganizationShort {
    created?: string | null
    updated?: string | null
}

export interface OrganizationFull extends Organization {
    accounts: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    }[],
}

export interface Dataplan {
    name: string
}

export interface Role {
    id: number
    label: ROLE
    name: string
    displayname?: string
    ts?: string
}

export interface Tag {
    id: number,
    name: string,
    comment: string
    accounts: AccountShort[]
}

export interface TagBase {
    id: number,
    name: string,
    hashname: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: { [key: string]: any },
}

export interface TagMinimal extends TagBase {
    control: boolean
}

export interface TagListElement extends TagBase {
    accounts: (AccountShort & { control: boolean })[],
}

export interface TagRequest {
    id?: number
    name: string,
    accounts: number[],
    hashname: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: { [key: string]: any },
    control?: string
}

export interface TagFull extends TagListElement {
    users?: UserProfileAccount[],
}

export interface RelatedOrganization {
    id: number,
    active: boolean,
    org: OrganizationShort
    orgacc: AccountShort,
    reason: string,
    validity: {
        lower: null,
        upper: null,
        bounds: string
    },
    acr_flag: boolean | null,
    added_by: number
}

export interface RelatedOrganizationRequest {
    id: number,
    body: {
        org?: number
        acr_flag?: boolean
        active?: boolean
        reason?: string,
        validity?: {
            bounds: string
        },
    }
}

export interface AccountRequest {
    name: string,
    org: number,
    emails: string[],
    extowner?: number,
    owner?: {
        email: string,
        firstname: string,
        surname?: string,
        lastname: string,
        phone: string,
    },
    rtype: number
}

export interface AccountUpdateRequest {
    id: number,
    nickname: string | null,
    org: number,
    owner: number,
    send_emails: boolean,
    emails: string[],
    send_sms: boolean,
    phones: string[],
    send_telegrams: boolean,
    telegrams: number[],
    rtype: number,
    name: string,
    agreement: string | null,
    agr_offer: boolean | null,
    flags: number,
    email: string | null,
    phone: string | null,
    contact: string | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: { [key: string]: any },
}

export interface AccountResponse {
    id: number,
    rtype: number,
    name: string,
    nickname: string | null,
    org: OrgMinimal,
    owner: UserProfile,
    logo: string | null,
    agreement: string | null,
    agr_offer: boolean | null,
    tags: TagMinimal[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: { [key: string]: any },
    send_emails: boolean,
    emails: string[],
    send_telegrams: boolean,
    telegrams: TgUser[],
    users: UserProfileAccount[],
    created: string | null,
    updated: string | null,
    flags: number,
    lang: Language
}

export interface Registration {
    org: {
        id?: number,
        name?: string
    },
    user: {
        email: string,
        firstname?: string | null,
        lastname?: string | null,
        surname?: string | null,
        phone?: string | null,
        lang?: Language,
        hide_fio?: boolean,
        hide_phone?: boolean,
        hide_email?: boolean,
        nickid?: string | null
    },
    id: number,
    email: string,
    account: AccountReg,
    roles: string[],
    email_verification: string,
    last_updated: string
}

export interface RegistrationRequest {
    email: string,
    profile: {
        company: string,
        company_typ?: number,
        firstname: string,
        surname?: string,
        lastname?: string,
        phone: string,
        accountname?: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additional?: any
    },
    roles: string[],
    email_verification: string,
    lang: 'ru' | 'en'
}

export interface TgUser {
    user_id: number,
    tg_userlang: string,
    tg_username: string | null,
    tg_userlastname: string | null,
    tg_userfirstname: string | null,
}

export interface UserProfileMinimal {
    id?: number,
    email: string,
    phone: string,
    firstname: string,
    surname: string | null,
    lastname: string,
    hide_email: boolean,
    hide_fio: boolean,
    hide_phone: boolean,
}

export interface UserProfileMinimalWithOwner extends UserProfileMinimal {
    owner: number,
}

export interface UserProfileAccount extends UserProfileMinimal {
    user_id: number,
    account_id: number,
    owner_id: number,
    nickid: string | null,
    roles: {
        id: number,
        name: string
    }[]
}

export interface UserProfile extends UserProfileMinimal {
    username: string,
    lang: Language
    avatar: string | null,
    roles: Role[],
    is_active: boolean,
    date_joined: string | null,
    last_login: string | null,
}

export interface UserResponse extends UserProfile {
    id: number,
    lang: Language,
    is_active: boolean,
    is_locked: boolean,
    lockmsg: string | null,
    date_joined: string,
    last_login: string,
    owner: AccountListElement,
}

export interface UserProfileResponse extends UserProfile {
    id: number,
    lang: Language,
    avatar: string,
    pd_agreement: boolean,
    is_active: boolean,
    date_joined: string,
    last_login: string
    roles: Role[]
}

export interface UserResponseFull extends UserResponse {
    accounts_for_roles: number[],
    is_active: boolean,
    is_locked: boolean,
    lockmsg: string | null,
    date_joined: string,
    last_login: string,
    accounts: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal | null,
        owner: UserProfileMinimal | null,
        logo: string | null,
        roles: {
            id: number,
            name: string
        }[]
    }[]
}

export interface UserRequest {
    id?: number,
    email: string,
    username: string,
    password?: string,

    lang: Language,
    send_emails?: boolean,
    emails?: string[],
    send_sms?: boolean,
    phones?: string[],
    phone: string,
    lastname: string,
    firstname: string,
    surname: string | null,
    avatar: string | null,
    hide_fio: boolean,
    hide_phone: boolean,
    account?: number,
    owner?: number,
    is_active?: boolean,
    is_locked?: boolean,
    lockmsg?: string,
    request_activation?: boolean,
    reset_roles?: boolean
}

export interface TotalUserData {
    all: number,
    new: number,
    offers: number,
    favorites: number,
    blocked: number
}

export interface ChangePasswordRequest {
    old_password: string,
    password: string
}

export interface ConfirmTokenRequest {
    uid: string,
    token: string,
    password: string
}

export interface ConfirmRequest {
    email: string,
    code: string
}

export interface ConfimResponse {
    email: string,
    email_used: boolean,
    email_verification: string,
    confirmed: boolean,
    token: string,
}

export interface EmailRequest {
    email: string,
    lang: Language,
    token?: string
}

export interface ValidateEmailResponse {
    email: string,
    resend_in: number,
    code_expired_in: number,
    sended: boolean
}

export interface ValidateEmailRequest {
    uid: string,
    token: string,
}

export interface UserAttach {
    id?: number,
    user: number,
    nickid: string | null,
    roles: number[]
}

export interface AccountAttach {
    id?: number,
    account: number,
    nickid: string | null,
    roles: number[]
}

const staggeredBaseQuery = retry(baseQueryWithReauth, {
    maxRetries: 0,
})

export const userApiV1 = createApi({
    reducerPath: 'userApiV1',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Accounts', 'Org', 'Profile', 'Reg', 'Roles', 'Users'],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        getAccountNotifications: builder.query<StatusResponse<{ access_requests: boolean, offers?: boolean }>, number>({
            query: (id) => `be/api/v1/account/${id}/notifications/`,
        }),
        setAccountNotifications: builder.mutation<StatusResponse<{ access_requests: boolean, offers?: boolean }>, { id: number, access_requests: boolean, offers?: boolean }>({
            query: (data) => ({
                url: `be/api/v1/account/${data.id}/notifications/`,
                method: 'POST',
                body: data
            })
        }),
        getCurrentAccount: builder.query<StatusResponse<AccountResponse>, void>({
            query: () => 'be/api/v1/user/account/',
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountList: builder.query<StatusPagesResponse<AccountUnit> & { results: { totals: TotalUserData } }, { [key: string]: string | number }>({
            query: (params) => `be/api/v1/user/account/clients/?${Object.keys(params).filter(k => params[k] || params[k] === 0).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getClientAccount: builder.query<StatusResponse<AccountClientUnit>, number>({
            query: (id) => `be/api/v1/user/account/clients/${id}/`,
            providesTags: (result, err, id) => [{ type: 'Accounts', id }]
        }),
        getAccountGroups: builder.query<StatusResponse<AclGroup[]>, number>({
            query: (accountId) => `be/api/v1/account/${accountId}/aclgroups/`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        accountGroupSet: builder.mutation<StatusResponse<AclGroup>, { accountId: number, groupId: number, members: number[] }>({
            query: (data) => ({
                url: `be/api/v1/account/${data.accountId}/aclgroups/${data.groupId}/`,
                method: 'PUT',
                body: { accounts: data.members }
            }),
            invalidatesTags: [{ type: 'Accounts' }],
        }),
        getAccountOrganizations: builder.query<StatusPagesResponse<OrganizationShort>, OffsetLimit>({
            query: (params) => `be/api/v1/user/account/organizations/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountTags: builder.query<StatusPagesResponse<TagFull>, OffsetLimit>({
            query: (params) => `be/api/v1/user/account/tags/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountTag: builder.query<StatusResponse<TagFull>, number>({
            query: (id) => `be/api/v1/user/account/tags/${id}/`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountDataplan: builder.query<StatusResponse<Dataplan[]>, void>({
            query: () => 'be/api/v1/user/account/dataplan/',
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountLogin: builder.query<StatusResponse<AccountLogin[]>, void>({
            query: () => 'be/api/v1/user/account/login/',
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getAccountRoles: builder.query<StatusResponse<(Role & { can_assign: boolean })[]>, void>({
            query: () => 'be/api/v1/user/account/roles/',
            providesTags: () => [{ type: 'Accounts' }]
        }),
        getUserAccountsManage: builder.query<StatusPagesResponse<AccountAvail>, void>({
            query: () => 'be/api/v1/user/account/managed/',
            providesTags: () => [{ type: 'Accounts' }]
        }),

        getAccounts: builder.query<StatusPagesResponse<AccountListElement>, OffsetLimit>({
            query: (params) => `be/api/v1/account/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Accounts' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getAccount: builder.query<StatusResponse<AccountResponse>, number | string>({
            query: (id: number | string) => {
                return `be/api/v1/account/${id}/`
            }
        }),
        createAccount: builder.mutation<StatusResponse<AccountResponse>, AccountRequest>({
            query: (data) => ({
                url: 'be/api/v1/account/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        getAccountAgreement: builder.query<StatusResponse<{ agr_offer: boolean | null }>, void>({
            query: () => 'be/api/v1/account/agreement/'
        }),
        updateAccountAgreement: builder.mutation<StatusResponse<AccountResponse>, void>({
            query: () => ({
                url: 'be/api/v1/account/agreement/',
                method: 'POST',
                body: { agr_offer: true }
            }),
        }),
        updateAccount: builder.mutation<StatusResponse<AccountResponse>, Partial<AccountUpdateRequest>>({
            query: (data) => ({
                url: `be/api/v1/account/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        deleteAccount: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/v1/account/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),

        createAccountLogo: builder.mutation<void, { accountId: number, file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `be/api/v1/account/${data.accountId}/logo/`,
                    method: 'PUT',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    },
                };
            },
            invalidatesTags: [{ type: 'Users' }]
        }),
        deleteAccountLogo: builder.mutation<void, number>({
            query: (accountId) => ({
                url: `be/api/v1/account/${accountId}/logo/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),

        getAccountsTags: builder.query<StatusPagesResponse<TagListElement>, OffsetLimit>({
            query: (params) => `be/api/v1/account/tags/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Accounts' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getAccountsTag: builder.query<StatusResponse<TagListElement>, number>({
            query: (tagId) => `be/api/v1/account/tags/${tagId}/`,
            providesTags: () => [{ type: 'Accounts' }]
        }),
        createAccoutTag: builder.mutation<StatusResponse<Tag[]>, TagRequest>({
            query: (data) => ({
                url: 'be/api/v1/account/tags/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        updateAccountTag: builder.mutation<StatusResponse<Tag[]>, Partial<TagRequest>>({
            query: (data) => ({
                url: `be/api/v1/account/tags/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        setAccountTagControl: builder.mutation<StatusResponse<Tag[]>, { id: number, control: number | null }>({
            query: (data) => ({
                url: `be/api/v1/account/tags/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        deleteAccountTag: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/v1/account/tags/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Accounts' }]
        }),
        getAccountsOrganizations: builder.query<StatusPagesResponse<Organization>, OffsetLimit>({
            query: (params) => `be/api/v1/account/organizations/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Org' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),

        getOrganization: builder.query<StatusResponse<OrganizationFull>, number | string>({
            query: (id: number | string) => {
                return `be/api/v1/account/organizations/${id}/`
            },
            providesTags: (result, error, id) => [{ type: 'Org', id }],
        }),
        createOrganization: builder.mutation<StatusResponse<Organization>, Omit<Organization, 'id'>>({
            query: (data) => ({
                url: 'be/api/v1/account/organizations/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Org' }]
        }),
        updateOrganization: builder.mutation<StatusResponse<Organization>, Partial<Organization>>({
            query: (data) => ({
                url: `be/api/v1/account/organizations/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Org' }]
        }),
        deleteOrganization: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/v1/account/organizations/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Org' }]
        }),

        getUserProfile: builder.query<StatusResponse<UserProfile>, void>({
            query: () => 'be/api/v1/user/profile/',
            providesTags: () => [{ type: 'Profile' }]
        }),
        updateUserProfile: builder.mutation<StatusResponse<UserProfile>, Partial<UserProfile>>({
            query: (data: UserProfile) => ({
                url: 'be/api/v1/user/profile/',
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Profile' }],
        }),
        updateUserProfileAgreement: builder.mutation<StatusResponse<UserProfile>, void>({
            query: () => ({
                url: 'be/api/v1/user/profile/',
                method: 'PATCH',
                body: { pd_agreement: true }
            }),
            invalidatesTags: [{ type: 'Profile' }],
        }),

        getUsers: builder.query<StatusPagesResponse<UserResponse>, OffsetLimit>({
            query: (params) => `be/api/v1/user/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: () => [{ type: 'Users' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getUser: builder.query<StatusResponse<UserResponseFull>, number | string>({
            query: (id: number | string) => {
                return `be/api/v1/user/${id}/`
            },
            providesTags: (result, error, id) => [{ type: 'Users', id }],
        }),
        deleteUser: builder.mutation<void, number>({
            query: (user_id) => ({
                url: `be/api/v1/user/${user_id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),
        createUser: builder.mutation<StatusResponse<UserResponse>, UserRequest>({
            query: (data) => ({
                url: 'be/api/v1/user/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),
        updateUser: builder.mutation<StatusResponse<UserResponse>, Partial<UserRequest>>({
            query: (data: UserRequest) => ({
                url: `be/api/v1/user/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Users' }, { type: 'Users', id }],
        }),

        getAllRoles: builder.query<StatusResponse<{ rtype: number, roles: (Role & { can_assign: boolean })[] }[]>, void>({
            query: () => 'be/api/v1/user/account/roles/all/',
            providesTags: () => [{ type: 'Roles' }]
        }),
        getUserAccountsAvailable: builder.query<StatusResponse<AccountAvail[]>, number | string>({
            query: (id) => `be/api/v1/user/${id}/accounts/available/`,
            providesTags: () => [{ type: 'Accounts' }]
        }),

        attachUserToAccount: builder.mutation<StatusResponse<UserAttach>, UserAttach>({
            query: (data) => ({
                url: `be/api/v1/account/${data.id}/users/attach/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Users' }],
        }),
        detachUserFromAccount: builder.mutation<StatusResponse<{ user: number }>, { id: number, user: number }>({
            query: (data) => ({
                url: `be/api/v1/account/${data.id}/users/detouch/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, data) => [{ type: 'Users', id: data.user }]
        }),

        attachAccountToUser: builder.mutation<StatusResponse<AccountAttach>, AccountAttach>({
            query: (data) => ({
                url: `be/api/v1/user/${data.id}/users/attach/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Users' }],
        }),
        detachAccountFromUser: builder.mutation<StatusResponse<{ account: number }>, { id: number, account: number }>({
            query: (data) => ({
                url: `be/api/v1/user/${data.id}/accounts/detouch/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Accounts' }],
        }),

        getAccountUsersAvailable: builder.query<StatusResponse<UserProfileMinimalWithOwner[]>, number | string>({
            query: (id) => `be/api/v1/account/${id}/users/available/`,
            providesTags: () => [{ type: 'Users' }]
        }),

        validateEmail: builder.mutation<StatusResponse<ValidateEmailResponse>, EmailRequest>({
            query: (data: EmailRequest) => ({
                url: 'be/api/v1/registration/email/validate/',
                method: 'POST',
                body: data
            })
        }),
        confirmEmail: builder.mutation<StatusResponse<ConfimResponse>, ConfirmRequest>({
            query: (data: ConfirmRequest) => ({
                url: 'be/api/v1/registration/email/confirm/',
                method: 'POST',
                body: data
            })
        }),
        getRegistrations: builder.query<StatusResponse<Registration[]>, void>({
            query: () => 'be/api/v1/registration/',
            providesTags: () => [{ type: 'Reg' }]
        }),
        getRegistration: builder.query<StatusResponse<Registration>, number>({
            query: (id: number) => `be/api/v1/registration/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Reg', id }],
        }),
        createRegistration: builder.mutation<StatusResponse<Registration>, RegistrationRequest>({
            query: (data) => ({
                url: 'be/api/v1/registration/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Reg' }]
        }),
        updateRegistration: builder.mutation<StatusResponse<Registration>, Partial<Registration>>({
            query: (data) => ({
                url: `be/api/v1/registration/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, data) => [{ type: 'Reg', id: data.id }]
        }),
        deleteRegistration: builder.mutation<void, number>({
            query: (id) => ({
                url: `be/api/v1/registration/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Reg' }]
        }),
        userApprove: builder.mutation<StatusResponse<Registration[]>, { id: number, data: ApproveRequest }>({
            query: ({ id, data }) => ({
                url: `be/api/v1/registration/${id}/approve/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: () => [{ type: 'Reg' }]
        }),
        // for users.ts
        getProfile: builder.query<StatusResponse<UserProfileResponse> & { ts?: string}, string | undefined>({
            query: () => 'be/api/v1/user/profile/',
            providesTags: () => [{ type: 'Profile' }],
            transformResponse: (response: StatusResponse<UserProfileResponse>, meta, arg) => {
                const result = { ...response, ts: arg };
                return result;
            },
        }),
        confirmPassword: builder.mutation<StatusResponse<null>, ConfirmTokenRequest>({
            query: (data: ConfirmTokenRequest) => ({
                url: 'be/api/v1/user/profile/password/reset/confirm/',
                method: 'POST',
                body: data
            })
        }),
        confirmActivation: builder.mutation<StatusResponse<null>, ConfirmTokenRequest>({
            query: (data: ConfirmTokenRequest) => ({
                url: 'be/api/v1/user/profile/activation/confirm/',
                method: 'POST',
                body: data
            })
        }),
        resetPassword: builder.mutation<StatusResponse<null>, number>({
            query: (id: number) => ({
                url: `be/api/v1/user/${id}/password/reset/`,
                method: 'POST',
                body: { inactivate: true }
            })
        }),
        resetProfilePassword: builder.mutation<StatusResponse<null>, { account: string, token: string }>({
            query: (data) => ({
                url: 'be/api/v1/user/profile/password/reset/',
                method: 'POST',
                body: { account: data.account, token: data.token }
            })
        }),
        setProfilePassword: builder.mutation<StatusResponse<null>, ChangePasswordRequest>({
            query: (data: ChangePasswordRequest) => ({
                url: 'be/api/v1/user/profile/password/set/',
                method: 'POST',
                body: data
            })
        }),
        validateActivation: builder.mutation<StatusResponse<void>, ValidateEmailRequest>({
            query: (data: ValidateEmailRequest) => ({
                url: 'be/api/v1/user/profile/activation/validate/',
                method: 'POST',
                body: data
            })
        }),
        validateChangePassword: builder.mutation<StatusResponse<void>, ValidateEmailRequest>({
            query: (data: ValidateEmailRequest) => ({
                url: 'be/api/v1/user/profile/password/reset/validate-token/',
                method: 'POST',
                body: data
            })
        }),
        createUserAvatar: builder.mutation<void, { file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: 'be/api/v1/user/profile/avatar/',
                    method: 'PUT',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    },
                };
            },
            invalidatesTags: [{ type: 'Users' }]
        }),
        deleteUserAvatar: builder.mutation<void, void>({
            query: () => ({
                url: 'be/api/v1/user/profile/avatar/',
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Users' }]
        }),
    }),
})

export const {
    useAccountGroupSetMutation,
    useAttachAccountToUserMutation,
    useAttachUserToAccountMutation,
    useConfirmActivationMutation,
    useConfirmEmailMutation,
    useConfirmPasswordMutation,
    useCreateAccountLogoMutation,
    useCreateAccountMutation,
    useCreateAccoutTagMutation,
    useCreateOrganizationMutation,
    useCreateRegistrationMutation,
    useCreateUserAvatarMutation,
    useCreateUserMutation,
    useDeleteAccountLogoMutation,
    useDeleteAccountMutation,
    useDeleteAccountTagMutation,
    useDeleteOrganizationMutation,
    useDeleteRegistrationMutation,
    useDeleteUserAvatarMutation,
    useDeleteUserMutation,
    useDetachAccountFromUserMutation,
    useDetachUserFromAccountMutation,
    useGetAccountAgreementQuery,
    useGetAccountDataplanQuery,
    useGetAccountGroupsQuery,
    useGetAccountListQuery,
    useGetAccountLoginQuery,
    useGetAccountNotificationsQuery,
    useGetAccountOrganizationsQuery,
    useGetAccountQuery,
    useGetAccountRolesQuery,
    useGetAccountsOrganizationsQuery,
    useGetAccountsQuery,
    useGetAccountsTagQuery,
    useGetAccountsTagsQuery,
    useGetAccountTagQuery,
    useGetAccountTagsQuery,
    useGetAccountUsersAvailableQuery,
    useGetAllRolesQuery,
    useGetClientAccountQuery,
    useGetCurrentAccountQuery,
    useGetOrganizationQuery,
    useGetProfileQuery,
    useGetRegistrationQuery,
    useGetRegistrationsQuery,
    useGetUserAccountsAvailableQuery,
    useGetUserAccountsManageQuery,
    useGetUserProfileQuery,
    useGetUserQuery,
    useGetUsersQuery,
    useLazyGetAccountAgreementQuery,
    useLazyGetAccountDataplanQuery,
    useLazyGetAccountGroupsQuery,
    useLazyGetAccountListQuery,
    useLazyGetAccountLoginQuery,
    useLazyGetAccountNotificationsQuery,
    useLazyGetAccountOrganizationsQuery,
    useLazyGetAccountQuery,
    useLazyGetAccountRolesQuery,
    useLazyGetAccountsOrganizationsQuery,
    useLazyGetAccountsQuery,
    useLazyGetAccountsTagQuery,
    useLazyGetAccountsTagsQuery,
    useLazyGetAccountTagQuery,
    useLazyGetAccountTagsQuery,
    useLazyGetAccountUsersAvailableQuery,
    useLazyGetAllRolesQuery,
    useLazyGetClientAccountQuery,
    useLazyGetCurrentAccountQuery,
    useLazyGetOrganizationQuery,
    useLazyGetProfileQuery,
    useLazyGetRegistrationQuery,
    useLazyGetRegistrationsQuery,
    useLazyGetUserAccountsAvailableQuery,
    useLazyGetUserAccountsManageQuery,
    useLazyGetUserProfileQuery,
    useLazyGetUserQuery,
    useLazyGetUsersQuery,
    useResetPasswordMutation,
    useResetProfilePasswordMutation,
    useSetAccountNotificationsMutation,
    useSetAccountTagControlMutation,
    useSetProfilePasswordMutation,
    useUpdateAccountAgreementMutation,
    useUpdateAccountMutation,
    useUpdateAccountTagMutation,
    useUpdateOrganizationMutation,
    useUpdateRegistrationMutation,
    useUpdateUserMutation,
    useUpdateUserProfileAgreementMutation,
    useUpdateUserProfileMutation,
    useUserApproveMutation,
    useValidateActivationMutation,
    useValidateChangePasswordMutation,
    useValidateEmailMutation,
} = userApiV1;
