/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as File } from '../assets/img/icons/file.svg';
import { ReactComponent as Tools } from '../assets/svg/Tools.svg';
import { formatBytes, isConsumer } from '../components/common';
import { collectUploads } from '../components/downloads/common';
import { UploadFilesContext, UploadFilesContextType } from '../components/downloads/UploaderContext';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import Table from '../components/mplat/DataTable/Table';
import TableBody from '../components/mplat/DataTable/TableBody';
import { TableColumn } from '../components/mplat/DataTable/TableColumn';
import TableHeader from '../components/mplat/DataTable/TableHeader';
import { TableHeaderColumn } from '../components/mplat/DataTable/TableHeaderColumn';
import TableListName from '../components/mplat/DataTable/TableListName';
import TableRow from '../components/mplat/DataTable/TableRow';
import MainHeader from '../components/mplat/MainHeader';
import { useRole } from '../hooks';
import { DownloadInfo, UnitUpload, useGetDowloadInfoQuery, useGetMediaHeaderMutation, useGetUploadsQuery } from '../services/content_v1';
import { BASE_URL } from '../services/reauth';
import { TRANSCODING_STATE_ID, TranscodingTaskResponse, useGetTranscodingTasksQuery } from '../services/transcode';


interface MainContentFilterProps {
    value: unknown,
    options: { value: any, label: string, count: number }[],
    setValue: (value: any) => void,
}

function MainContentFilter({ value, options, setValue }: MainContentFilterProps) {
    return (
        <div className="main__content-filter">
            <div className="main__types">
                {options.map(option => <span key={option.value} className={'main__types-item' + (option.value === value ? ' _active' : '')} onClick={() => setValue(option.value)}>{option.label} {option.count ? <span className="main__types-item-counter">· {option.count}</span> : null}</span>)}
            </div>
        </div>
    );
}

export function toMplatDate(date?: string | null, onlyDate?: boolean) {
    try {
        if (date) {
            const d = new Date(date);
            if (onlyDate) return d.toLocaleDateString();
            return d.toLocaleString();
        }
        return '---';
    } catch {
        if (onlyDate) return date?.replace('T', ' ').split('.')[0]?.substring(0, 10) || '---'
        return date?.replace('T', ' ').split('.')[0]?.substring(0, 16) || '---';
    }
}

export function toMplatBackendDate(date?: Date, onlyDate?: boolean) {
    try {
        if (date) {
            const d = new Date(date);
            let textDate = ''
            textDate += d.getFullYear();
            textDate += '-';
            textDate += ('0' + (d.getMonth() + 1)).slice(-2);
            textDate += '-';
            textDate += ('0' + d.getDate()).slice(-2);
            if (onlyDate) return textDate;
            textDate += ' ';
            textDate += ('0' + d.getHours()).slice(-2);
            textDate += ':';
            textDate += ('0' + (d.getMinutes())).slice(-2);
            textDate += ':';
            textDate += ('0' + d.getSeconds()).slice(-2);
            return textDate;
        }
        return undefined;
    } catch {
        return undefined;
    }
}

interface TranscodeInfoItemProps {
    task: TranscodingTaskResponse
}

function TranscodeInfoItem({ task }: TranscodeInfoItemProps) {
    const [getHeader] = useGetMediaHeaderMutation();
    const role = useRole();

    function toStatus(taskResponse: TranscodingTaskResponse) {
        let status: string | JSX.Element;
        switch (taskResponse.state) {
            case TRANSCODING_STATE_ID.CREATED:
            case TRANSCODING_STATE_ID.PROCESSED:
            case TRANSCODING_STATE_ID.CONVERTED:
            case TRANSCODING_STATE_ID.PACKED:
                status = `${taskResponse.progress}%`
                break;
            case TRANSCODING_STATE_ID.FINISHED:
                status = <a href="#download" className="btn _min" onClick={(e) => {
                    e.preventDefault();
                    getHeader(BASE_URL.substring(0, BASE_URL.length - 1) + taskResponse.transcoded.url).then(() => {
                        window.open(BASE_URL.substring(0, BASE_URL.length - 1) + taskResponse.transcoded.url);
                    });
                }}>
                    <Trans>
                        Download
                    </Trans>
                </a>
                break;
            case TRANSCODING_STATE_ID.FAILED:
                status = <span style={{ color: 'var(--c-red)' }}>Ошибка [{taskResponse.error}]</span>
                break;
            default:
                status = taskResponse.statename;
                break;
        }

        return (
            <>
                {status}
            </>
        );
    }

    return (
        <TableRow key={task.id}>
            <TableColumn className="_id" style={{ whiteSpace: 'nowrap' }}>{toMplatDate(task.created)}</TableColumn>
            <TableColumn className="_tools">
                <Trans>
                    Transcode
                </Trans>
            </TableColumn>
            <TableColumn className="_content">
                <TableListName url={task.content.covers?.at(0)} title={task.content.title} link={isConsumer(role.label) ? `/content/view/${task.content.id}/` : `/content/${task.content.id}/`} />
            </TableColumn>
            <TableColumn className="_files">
                <div className="file-name">
                    <File /> {task.mediafile.name}
                </div>
            </TableColumn>
            <TableColumn className="_profile">HLS · H.264 · AAC</TableColumn>
            <TableColumn className="" style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
                {toStatus(task)}
                {task.error ? <span style={{ color: 'red' }}>{task.error}</span> : null}
            </TableColumn>
        </TableRow>
    );
}

interface UploadInfoItemProps {
    unit: UnitUpload
}

function UploadInfoItem({ unit }: UploadInfoItemProps) {
    const role = useRole();
    const { t } = useTranslation();
    return (
        <TableRow key={unit.uuid}>
            <TableColumn className="_id" style={{ whiteSpace: 'nowrap' }}>{toMplatDate(unit.created)}</TableColumn>
            <TableColumn className="_tools">Загрузка</TableColumn>
            <TableColumn className="_content">
                <TableListName url={unit.content?.covers?.at(0)} title={unit.content?.title} link={isConsumer(role.label) ? `/content/view/${unit.unit_id}` : `/content/${unit.unit_id}`} />
            </TableColumn>
            <TableColumn className="_files">
                <div className="files-list">
                    <div className="files-list__trigger">
                        <div className="file-name">
                            <>
                                <File /> {unit.cat === 3 ? `${t('Demo').toLocaleUpperCase()}: ` : ''} {unit.name}
                            </>
                        </div>
                    </div>
                </div>
            </TableColumn>
            <TableColumn className="_profile"></TableColumn>
            <TableColumn className="_status">{unit.progress.toFixed(0)}%</TableColumn>
        </TableRow>
    );
}

interface DownloadInfoItemProps {
    task: DownloadInfo
}

function DownloadInfoItem({ task }: DownloadInfoItemProps) {
    const role = useRole();

    return (
        <TableRow key={task.id}>
            <TableColumn className="_id" style={{ whiteSpace: 'nowrap' }}>{toMplatDate(task.created)}</TableColumn>
            <TableColumn className="_tools">
                Скачивание
            </TableColumn>
            <TableColumn className="_content">
                <TableListName url={task.content.covers?.at(0)} title={task.content.title} link={isConsumer(role.label) ? `/content/view/${task.content.id}/` : `/content/media/${task.content.id}/`} />
            </TableColumn>
            <TableColumn className="_files">
                <div className="file-name">
                    <File /> {task.file.name}
                </div>
            </TableColumn>
            <TableColumn className="_profile">{formatBytes(task.avgrate)}/<Trans>SHORT_SECOND</Trans></TableColumn>
            <TableColumn className="_status">
                {task.percent.toFixed(0)}%
            </TableColumn>
        </TableRow>
    );
}

interface ToolItem {
    id: string,
    content: string,
    item: JSX.Element
}

export default function ToolsPage() {
    const { data: uploadingTasks } = useGetUploadsQuery(undefined, { pollingInterval: 5000 });
    const { data: downloads } = useGetDowloadInfoQuery(undefined, { pollingInterval: 5000 });
    const { t } = useTranslation();

    const [params, setParams] = useState<Record<string, string>>({});
    const { uploadFiles } = useContext<UploadFilesContextType>(UploadFilesContext);
    const { data: transcodingTasks } = useGetTranscodingTasksQuery({ offset: 0, limit: -1, ...params }, { pollingInterval: 5000 });
    const [uploads, setUploads] = useState<{ [key: string]: UnitUpload }>({});

    const [filter, setFilter] = useState('all');
    const availFilters = [
        { value: 'all', label: 'Все', count: 0 },
        { value: 'transcode', label: 'Транскодинг', count: 0 },
        { value: 'upload', label: 'Загрузка', count: 0 },
        { value: 'download', label: 'Скачивание', count: 0 },
    ]
    const [ordering, setOrdering] = useState('');

    const [fullState, setFullState] = useState<ToolItem[]>([]);

    useEffect(() => {
        const tmp: ToolItem[] = [];
        if (filter === 'all' || filter === 'transcode') {
            transcodingTasks?.results?.data.forEach(task => {
                tmp.push({
                    id: task.created || '',
                    content: task.content.title,
                    item: <TranscodeInfoItem key={task.created + task.id} task={task} />
                })
            });
        }
        if (filter === 'all' || filter === 'upload') {
            Object.values(uploads).forEach(unit => {
                tmp.push({
                    id: unit.created || '',
                    content: unit.content?.title || '',
                    item: <UploadInfoItem key={unit.uuid} unit={unit} />
                });
            });
        }
        if (filter === 'all' || filter === 'download') {
            downloads?.results?.forEach(unit => {
                tmp.push({
                    id: unit.created || '',
                    content: unit.content?.title || '',
                    item: <DownloadInfoItem key={unit.created + unit.id} task={unit} />
                });
            })
        }

        tmp.sort(itemCompare);

        setFullState(tmp);
        // eslint-disable-next-line
    }, [transcodingTasks, uploads, downloads, ordering, filter])

    useEffect(() => {
        setParams({
            ordering: ordering,
        });
    }, [ordering]);

    useEffect(() => {
        setUploads(collectUploads(uploadFiles, uploadingTasks?.results.data, undefined, -1));
    }, [uploadFiles, uploadingTasks]);

    function itemCompare(a: ToolItem, b: ToolItem): number {
        switch (ordering) {
            case 'file__unit__title':
                return String(a.content) > String(b.content) ? 1 : -1;
            case '-file__unit__title':
                return String(a.content) > String(b.content) ? -1 : 1;
            case '-id':
                return String(a.id) > String(b.id) ? 1 : -1;
            default:
                return String(a.id) > String(b.id) ? -1 : 1;
        }
    }

    return (
        <Content>
            <ContentShadow />
            <MainHeader icon={<Tools />} label={t('Tools')} />

            <MainContentFilter value={filter} options={availFilters} setValue={setFilter} />

            <Table className="tools-list">
                <TableHeader>
                    <TableHeaderColumn header={t('Date')} field="id" ordering={ordering} setOrdering={setOrdering} />
                    <TableHeaderColumn header={t('Tools')} />
                    <TableHeaderColumn header={t('Content')} field="file__unit__title" ordering={ordering} setOrdering={setOrdering} />
                    <TableHeaderColumn header={t('Files')} className="_files" />
                    <TableHeaderColumn header={t('Profile')} />
                    <TableHeaderColumn header={t('Status')} className="_status" />
                </TableHeader>
                <TableBody>
                    {fullState.map(info => info.item)}
                </TableBody>
            </Table>
        </Content >
    );
}
