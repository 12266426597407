import { Outlet } from 'react-router-dom';
import { useAcc, useAuth } from '../../hooks';
import AccountSelectionPage from '../../pages/AccountSelectionPage';
import AgreementPage from '../../pages/AgreementPage';
import IndexPage from '../../pages/IndexPage';

function PrivateOutlet() {
    const auth = useAuth();
    const acc = useAcc();

    return auth?.user ? (
        auth?.account ? (
            acc.agr_offer !== null ?
            <Outlet /> :
            <AgreementPage />
        ) :
        <AccountSelectionPage />
    ) : (
        <IndexPage />
    )
}

export default PrivateOutlet;
