import { HTMLAttributes } from 'react';

interface TableBodyProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode,
}

function TableBody({ children, className, ...props }: TableBodyProps) {
    return (
        <div
            className={'table-list__body ' + (className || '')}
            {...props}
        >
            {children}
        </div>
    );
}

export default TableBody;
