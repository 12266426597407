/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';

interface MobileFilterItemProps {
    label: string,
    options: { value: any; label: string; }[],
    value: any[],
    setValue: (value: any) => void,
}

export function MobileFilterItem({ label, options, value, setValue }: MobileFilterItemProps) {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const widthRef = useRef<number | null>(null);
    const [full, setFull] = useState(false);
    const [oversize, setOversize] = useState<boolean | null>(null);

    // eslint-disable-next-line
    useEffect(() => {
        if (!elementRef.current) return; // wait for the elementRef to be available
        setOversize(elementRef.current.clientHeight > 68);
    })

    useEffect(() => {
        if (!elementRef.current) return; // wait for the elementRef to be available

        const resizeObserver = new ResizeObserver(() => {
            if (elementRef.current && widthRef.current !== elementRef.current.clientWidth) {
                widthRef.current = elementRef.current.clientWidth;
                setOversize(elementRef.current.clientHeight > 68);
            }
        });
        resizeObserver.observe(elementRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    return (
        <div className="mobile-filter__item">
            <div className="mobile-filter__item-name">
                {label}
            </div>
            <div className={'mobile-filter__item-list' + (oversize ? ' _more' : '')} style={full ? { maxHeight: 'none', overflow: 'hidden', maxWidth: '100%' } : {}}>
                <div ref={elementRef} className={'mobile-filter__item-list'} style={{ marginBottom: 0 }}>
                    <div className={'mobile-filter__item-list-inner'} data-name="filter-language">
                        {options.map(item => <span
                            key={item.value}
                            className={'mobile-filter__item-list-option' + (value.includes(item.value) ? ' _active' : '')}
                            onClick={() => {
                                let tmp: any[] = [];
                                if (!value.includes(item.value)) {
                                    tmp = [...value, item.value];
                                } else {
                                    tmp = value.filter(v => v !== item.value);
                                }
                                setValue(tmp);
                            }}
                        >
                            {item.label}
                        </span>)}
                    </div>
                </div>
            </div>
            {oversize && <div className={'mobile-filter__item-full' + (full ? ' _active' : '')} onClick={() => { setFull(!full); }}>{full ? 'Скрыть' : 'Показать все'}</div>}
        </div>
    );
}