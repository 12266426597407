import { changeLanguage, t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logout } from '../assets/svg/logout.svg';
import { ROLE, useIsProvider, ymHit } from '../components/common';
import Content from '../components/mplat/Content';
import Header1 from '../components/mplat/Header1';
import ImageLoader from '../components/mplat/ImageLoader';
import Section from '../components/mplat/Section';
import { ACCOUNT_TYPES } from '../const';
import { useAuth, useBodyClass, useRole } from '../hooks';
import { useAccountLoginMutation } from '../services/api';
import { AccountLogin, useLazyGetAccountLoginQuery } from '../services/users_v1';


interface AccountProps {
    item: AccountLogin
}

function Account({ item }: AccountProps) {
    const [accountLogin] = useAccountLoginMutation();
    const auth = useAuth();
    const navigate = useNavigate();

    const [error, setError] = useState('');

    return (
        <div className="user-settings__list-item" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
            accountLogin({
                refresh: auth.refreshToken,
                username: auth.user,
                account: item.id.toString()
            }).unwrap().then((z) => {
                if (!z.agr_offer) {
                    window.location.pathname !== '/agreement' && navigate('/agreement');
                    return;
                }
                // TODO убрать после нормального перевода
                changeLanguage('ru');
                window.location.pathname === '/select-accounts' && navigate('/');
            }).catch((e) => {
                setError(e.message);
            });
        }}>
            {item.logo && <ImageLoader url={item.logo} maxWidth="30px" maxHeight="30px" rounded />}&nbsp;<Link to="" className="link-accent">@{item.nickname || item.id} · {item.name} · {t(ACCOUNT_TYPES[item.rtype])}</Link>
            {error && <><br />{error}</>}
        </div>
    );
}

interface AccountsListProps {
    list: AccountLogin[];
}

function AccountsList({ list }: AccountsListProps) {
    return (
        <Section label={undefined}>
            <div className="user-settings__list">
                {list.length > 0 ?
                    list.map(item => <Account key={item.id} item={item} />)
                    :
                    <div className="user-settings__list-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Trans>account:NO_ROLES</Trans>
                    </div>
                }
            </div>
        </Section>
    );
}

interface ContactsProps {
    children?: React.ReactNode;
}

function Accounts({ children }: ContactsProps) {
    return (
        <div className="user-settings">
            {children}
        </div>
    );
}

function AccountsHeading() {
    return (
        <Header1>
            <Trans>My accounts</Trans>
        </Header1>
    );
}

function AccountsName() {
    return (
        <p className="contacts__name">
            <strong></strong><br />
        </p>
    );
}

function AccountSelectionPage() {
    const auth = useAuth();
    const role = useRole();
    const dispatch = useDispatch();
    const [bodyClasses, setBodyClasses] = useState<string[]>([]);
    const [fetchAccounts, { data: accounts }] = useLazyGetAccountLoginQuery();

    const isProvider = useIsProvider();

    // const accounts: AccountShort[] = useSelector((state: RootState) => state.auth.accounts);
    useEffect(() => ymHit('/select-accounts'), []);

    useEffect(() => {
        fetchAccounts();
    }, []);

    useEffect(() => {
        const tmp: string[] = [];
        if (auth.account) {
            if (isProvider || role.label === ROLE.SITE_ADMIN) {
                tmp.push('page-producer');
            } else {
                tmp.push('page-subscriber');
            }
        } else {
            tmp.push('page-subscriber');
        }
        setBodyClasses(tmp);
    }, [auth.account, auth.user, dispatch, role.label]);

    useBodyClass(bodyClasses);

    return (
        <Content>
            <div className="content__side-btn">
                <Link to="/logout" title="Выйти">
                    <Logout />
                </Link>
            </div>
            <Accounts>
                <AccountsHeading />
                <AccountsName />
                <AccountsList list={accounts?.results || []} />
            </Accounts>
        </Content>
    )
}

export default AccountSelectionPage;
