/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as Close } from '../../assets/svg/CloseBlack.svg';
import { useHtmlClass } from '../../hooks';

interface HomePopupProps {
    isOpen: boolean,
    onClose: () => void,
    children?: React.ReactNode,
    className?: string,
    minWidth?: number,
    closeOnBack?: boolean,
}

export function Modal({ isOpen, onClose, children, className, minWidth, closeOnBack }: HomePopupProps) {
    const refDiv = useRef(null);
    const body = document.body;
    const root = document.getElementsByClassName('main-wrapper')[0];
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const _minWidth = minWidth || 380;

    // TODO пофиксить моргание шапки
    useHtmlClass((isOpen && scrollPosition !== 0) ? ['_header-fixed'] : []);

    useEffect(() => {
        if (isOpen) {
            setScrollPosition(window.scrollY);
            // (root as any).style.top = `-${window.scrollY}px`;
            // (root as any).style.position = 'fixed';
            // (root as any).style.left = 0;
            // (root as any).style.right = 0;
            setShow(isOpen);
            setTimeout(() => {
                setVisible(isOpen);
            }, 50)

        } else {
            setVisible(isOpen);
            setTimeout(() => {
                setShow(isOpen);
                (root as any).style.top = '';
                (root as any).style.position = '';
                window.scrollTo({ top: scrollPosition });
            }, 300);
        }
        // eslint-disable-next-line
    }, [isOpen]);

    return ReactDOM.createPortal(
        show ?
            <>
                <div
                    ref={refDiv}
                    className={'popup__back' + (show ? ' _visible' : '') + (visible ? ' _show' : '')}
                    style={{ transition: 'all 0.3s ease 0s', overflow: 'auto' }}
                    onClick={(e) => closeOnBack && e.target === refDiv.current && onClose()}
                >
                    <div className={'popup__container' + (show ? ' _visible' : '') + (visible ? ' _show' : '') + ' ' + className} style={{ transition: 'all 0.3s ease 0s' }}>
                        <div className="popup__body" style={{ minWidth: `${_minWidth}px` }}>
                            <div className="popup__close" onClick={() => onClose()}>
                                <Close />
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </>
            :
            <React.Fragment />
        , body);
}