import { Modal } from '../Modal';


interface SuccessContentProps {
    title: string,
    content: string,
    onClose: () => void
}

function SuccessContent({ title, content, onClose }: SuccessContentProps) {
    return (
        <div className="form-message _success">
            <div className="form-message__icon"></div>
            <h2 className="popup__heading form-message__heading" style={{ textAlign: 'center' }}>
                {title}
            </h2>
            <div className="form-message__copy" style={{ textAlign: 'center' }}>
                {content}
            </div>
            <div className="form-message__ok">
                <span className="btn" onClick={() => onClose()}>
                    OK
                </span>
            </div>
        </div>
    );
}

interface ErrorModalProps {
    title: string,
    content: string,
    isOpen: boolean,
    onClose: () => void,
}

export function SuccessModal({ title, content, isOpen, onClose }: ErrorModalProps) {
    return (
        <Modal className={'popup_message'} isOpen={isOpen} onClose={() => onClose()}>
            <SuccessContent title={title} content={content} onClose={onClose} />
        </Modal >
    );
}
