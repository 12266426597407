import React from 'react';

interface TableColumnProps {
    className?: string
    children?: React.ReactNode,
    style?: React.CSSProperties | undefined,
    title?: string,
}

export function TableColumn({ className, children, style, title }: TableColumnProps) {
    return (
        <div
            className={'table-list__col ' + (className || '')}
            style={style}
            title={title}
        >
            {children}
        </div>
    );
}