interface TableProps {
    className?: string,
    children?: React.ReactNode,
}

function Table({ className, children }: TableProps) {

    return (
        <div className="table-list__wrapper">
            <div className={'table-list ' + (className || '')}>
                {children}
            </div>
        </div>
    );
}

export default Table;
