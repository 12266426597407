import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// import InputMask from 'react-input-mask';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { Controller, FieldError, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import PI_ru from 'react-phone-input-2/lang/ru.json';
import { ReactComponent as Triangle } from '../../../assets/svg/alert-triangle.svg';
import { useLanguage } from '../../../hooks';
import { useConfirmEmailMutation, useCreateRegistrationMutation, useResetProfilePasswordMutation, useValidateEmailMutation } from '../../../services/users_v1';
import { ROLE, extractErrorDetail } from '../../common';
import Button from '../Button/Button';
import Form from '../Form';
import { Modal } from '../Modal';
import MultiSelect from '../MultiSelect';
import Select from '../Select';


enum STEPS {
    VERIFY_EMAIL = 0,
    CONFIRM_CODE = 1,
    REGISTRATION = 2,
    SUCCESS = 3,
    ERROR = -1,
}

interface VerifyEmailProps {
    next: () => void,
    token: string,
    setToken: (token: string) => void,
    setError: (error: string) => void,
    setEmail: (email: string) => void,
    setExpire: (value: number) => void,
    setResend: (value: number) => void,
}

function VerifyEmail({ next, token, setToken, setEmail, setExpire, setResend, setError }: VerifyEmailProps) {
    const [validate] = useValidateEmailMutation();
    const { t } = useTranslation();

    const defaultEmail = {
        email: ''
    };
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultEmail });
    const lang = useLanguage();

    async function onValidate(value: string, validatedToken: string) {
        validate({ email: value, token: validatedToken, lang }).unwrap().then((response) => {
            if (response.status.code === 0) {
                setEmail(value);
                setExpire(response.results.code_expired_in);
                setResend(response.results.resend_in);
                // setResend(15);
                next();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setError((response as any).error.data?.status.msg);
            }
        }).catch((e) => {
            setError(extractErrorDetail(e));
        });
    }

    const getFormErrorMessage = (name: keyof typeof errors): JSX.Element | undefined => {
        const e = errors[name];
        return e && <div className="form__input-error-message">{(e as FieldError).message}</div>
    };

    return (
        <Form
            action="#"
            className="form-feedback"
            id="feedback"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <h2 className="popup__heading">
                <Trans>
                    modal:SIGN_UP_EMAIL
                </Trans>
            </h2>
            <Controller
                name="email"
                control={control}
                rules={{
                    required: t('Email is required'),
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('modal:INVALID_EMAIL')
                    }
                }}
                render={({ field, fieldState }) => (
                    <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                        <input id={field.name} {...field} placeholder={t('Email')} autoFocus className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                        {getFormErrorMessage('email')}
                    </div>
                )}
            />

            <SmartCaptcha language={lang} sitekey="ysc1_CGbTGnEniUByIpNrHaWkRb2DEfaigOpUTJY0PFNc79bbb80c" onSuccess={setToken} />

            {token && <div className="form__submit" style={{ marginTop: '20px' }}>
                <Button
                    disabled={!token}
                    onClick={handleSubmit((data) => {
                        onValidate(data.email, token);
                    })}>
                    <Trans>Next</Trans>
                </Button>
            </div>
            }
        </Form>
    );
}

function toMinutes(resend: number) {
    const second = (resend % 60);
    const minutes = (resend / 60);
    const m = minutes.toFixed(0)
    return `${minutes < 10 ? '0' + m : m}:${second < 10 ? '0' + second : second}`;
}

interface CodeVerificationProps {
    token: string,
    email: string,
    setSecret: (s: string) => void,
    expire: number,
    setExpire: Dispatch<SetStateAction<number>>,
    resend: number,
    setResend: Dispatch<SetStateAction<number>>,
    next: () => void,
    setError: (error: string) => void
}

function CodeVerification({ email, setSecret, expire, setExpire, resend, setResend, next, setError }: CodeVerificationProps) {
    const lang = useLanguage();

    const [confirm] = useConfirmEmailMutation();
    const [validate] = useValidateEmailMutation();
    const [reset] = useResetProfilePasswordMutation();

    const [showResendButton, setShowResendButton] = useState(true);

    const { t } = useTranslation();

    const defaultCode = {
        code: ''
    };
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultCode });

    useEffect(() => {
        const clearId = setInterval(() => {
            if (resend > 0) {
                setResend(v => v > 0 ? v - 1 : 0);
            }
            if (expire > 0) {
                setExpire(v => v > 0 ? v - 1 : 0);
            }
        }, 1000);
        return () => clearInterval(clearId);
        // eslint-disable-next-line
    }, []);

    const getFormErrorMessage = (name: keyof typeof errors): JSX.Element | undefined => {
        const e = errors[name];
        return e && <div className="form__input-error-message">{(e as FieldError).message}</div>
    };

    async function onCheck(value: string) {
        confirm({ email: email, code: value }).unwrap().then((response) => {
            if (response.status.code === 0) {
                if (response.results.email_used) {
                    reset({ account: email, token: response.results.token }).unwrap().then(() => {
                        setError(t('Email already registered'));
                    }).catch((e) => {
                        if (e.data?.response?.status?.msg) {
                            setError(e.data?.response?.status?.msg);
                        } else {
                            setError(extractErrorDetail(e));
                        }
                    });
                    setResend(0);
                } else if (!response.results.confirmed) {
                    setError(t('Confirmation code is wrong'));
                } else {
                    setSecret(response.results.email_verification);
                    next();
                }
            }
        }).catch((e) => {
            setError(extractErrorDetail(e));
        });
    }

    return (
        <>
            <Form
                action="#"
                className="form-feedback"
                id="feedback"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <h2 className="popup__heading">
                    <Trans>
                        modal:CODE_CONFIRM_MESSAGE
                    </Trans>
                    {' '}
                    {email}
                </h2>
                <Controller
                    name="code"
                    control={control}
                    rules={{
                        required: t('Code is required'),
                        pattern: {
                            value: /^[0-9]{6}$/i,
                            message: 'Invalid code'
                        }
                    }}
                    render={({ field, fieldState }) => {
                        return <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                            <input
                                id={field.name}
                                {...field}
                                placeholder={t('Code')}
                                autoFocus
                                className={'form__input _required' + (fieldState.error ? ' _error' : '')}
                                onKeyDown={(e) => e.code === 'Enter' && field.value.length === 6 && onCheck(field.value)}
                            />
                            {getFormErrorMessage('code')}
                        </div>
                    }}
                />
                {resend ?
                    <div style={{ textAlign: 'center' }}>
                        <Trans>Re-request code after</Trans><br /> {toMinutes(resend)}
                    </div>
                    :
                    (
                        showResendButton ?
                            <div style={{ textAlign: 'center', marginBottom: '12px' }}>
                                <Button onClick={() => {
                                    setShowResendButton(false);
                                }}>
                                    <Trans>
                                        Send again
                                    </Trans>
                                </Button>
                                <br />
                            </div>
                            :
                            <div className="form__item">
                                <SmartCaptcha language={lang} sitekey="ysc1_CGbTGnEniUByIpNrHaWkRb2DEfaigOpUTJY0PFNc79bbb80c" onSuccess={(alt_token) => {
                                    validate({ email: email, token: alt_token, lang }).unwrap().then((response) => {
                                        if (response.status.code === 0) {
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            setSecret((response as any).debug?.data?.code);
                                            setResend(response.results.resend_in);
                                            setExpire(response.results.code_expired_in);
                                        } else {
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            setError((response as any).error.data?.status.msg);
                                        }
                                    }).catch((e) => {
                                        setError(extractErrorDetail(e));
                                    });
                                }} />
                            </div>
                    )
                }

                <div className="form__submit" style={{ textAlign: 'center' }}>
                    <Button onClick={handleSubmit((data) => {
                        onCheck(data.code);
                    })}>
                        <Trans>Check</Trans>
                    </Button>
                </div>
            </Form>
            {expire < 600 ?
                <div style={{ textAlign: 'center', marginTop: '12px' }}>
                    <Trans>The code is valid for</Trans> {expire} <Trans>second</Trans>
                </div>
                :
                null
            }
        </>
    );
}

interface RegistrationFormProps {
    secret: string,
    email: string,
    next: () => void,
    setError: (error: string) => void
}

function RegistrationForm({ secret, email, next, setError }: RegistrationFormProps) {
    const [setRequest] = useCreateRegistrationMutation();
    const lang = useLanguage();

    const { t } = useTranslation();

    const [spec, setSpec] = useState<string[]>([]);
    const availSpecs = [
        { value: 'prefferedSourceUnit', label: t('Content unit') },
        { value: 'prefferedSourceTV', label: t('TV channel') },
    ];

    // пока администратор будет назначать роли
    const [roles, setRoles] = useState('consumers');
    const availRoles = [
        { value: 'consumers', label: t('Consumer') },
        { value: 'providers', label: t('Provider') },
        { value: 'partners', label: t('Partner') },
    ];

    // const orgTypes = Object.entries(ORG_MAP).map((item) => ({ label: item[1], value: item[0] }));

    const defaultValues = {
        firstname: '',
        lastname: '',
        phone: '',
        company: '',
        company_typ: 0,
        surname: '',
        accountname: '',
        additional: '',
        lang: 'ru'
    }
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const getFormErrorMessage = (name: keyof typeof errors): JSX.Element | undefined => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = (errors as any)[name];
        return e && <div className="form__input-error-message">{(e as FieldError).message}</div>
    };

    return (
        <Form action="#" className="form-register" id="register">
            <h2 className="popup__heading">
                <Trans>
                    Sign Up
                </Trans>
                {/* {email} */}
            </h2>

            <Controller name="firstname" control={control} rules={{ required: t('Firstname is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <input id={field.name} {...field} placeholder={t('Firstname')} autoFocus className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                    {getFormErrorMessage('firstname')}
                </div>
            )} />
            <Controller name="lastname" control={control} rules={{ required: t('Lastname is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <input id={field.name} {...field} placeholder={t('Lastname')} className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                    {getFormErrorMessage('lastname')}
                </div>
            )} />

            {/* <Controller name="surname" control={control} rules={{ required: t('Surname is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <input id={field.name} {...field} placeholder={t('Surname')} className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                    {getFormErrorMessage('surname')}
                </div>
            )} /> */}

            <Controller name="company" control={control} rules={{ required: t('Company is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <input id={field.name} {...field} placeholder={t('Legal Entity')} className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                    {getFormErrorMessage('company')}
                </div>
            )} />

            {/* <Controller name="company_typ" control={control} rules={{ required: t('Company type is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <Select className={(fieldState.error ? ' _error' : '')} value={field.name} options={orgTypes} onChange={(e) => field.onChange(e)} />
                    {getFormErrorMessage('company_typ')}
                </div>
            )} /> */}

            {/* <Controller name="accountname" control={control} rules={{ required: t('Accountname is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <input id={field.name} {...field} placeholder={t('Account Name')} className={'form__input _required' + (fieldState.error ? ' _error' : '')} />
                    {getFormErrorMessage('accountname')}
                </div>
            )} /> */}

            <Controller name="phone" control={control} rules={{ required: t('Phone is required') }} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    {/* <InputMask mask="+7 (999) 999-99-99" id={field.name} {...field} placeholder={t('Phone')} className={'form__input _required' + (fieldState.error ? ' _error' : '')} /> */}
                    {/* <input id={field.name} {...field} placeholder={t("Phone")} className={"form__input _required" + (fieldState.error ? ' _error' : '')} /> */}
                    <PhoneInput
                        inputClass={'form__input _required' + (fieldState.error ? ' _error' : '')}
                        {...field}
                        country="ru"
                        placeholder="+7 (495) 123-45-67"
                        specialLabel={''}
                        localization={lang === 'ru' ? PI_ru : undefined}
                    />
                    {getFormErrorMessage('phone')}
                </div>
            )} />

            <div className="form-register__role">
                <strong>{t('Choose a role')}</strong>
                <Select radio value={roles} options={availRoles} onChange={(v) => setRoles(v)} />
            </div>

            <div className="form-register__role">
                <strong>{t('Choose a specialization')}</strong>
                <MultiSelect value={spec} options={availSpecs} setValue={(v) => setSpec(v)} />
            </div>

            <Controller name="additional" control={control} render={({ field, fieldState }) => (
                <div className={'form__item' + (fieldState.error ? ' _error' : '')}>
                    <textarea id={field.name} {...field} className="form__input" placeholder={t('Comment')}></textarea>
                </div>
            )} />

            <p className="form__agreement">
                <Trans i18nKey="modal:PERSONAL_DATA_PROCESSING">
                    By clicking “Send” I agree to
                    <a href="https://media.msk-ix.ru/download/legal/ru/pd-consent_MSK-IX.pdf" target="_blank" rel="noreferrer">personal data</a>
                    <a href="https://media.msk-ix.ru/download/legal/ru/ServiceAgreement_Mediabaza.pdf" target="_blank" rel="noreferrer">user agreement</a>
                </Trans>
            </p>
            <div className="form__submit">
                <Button type="submit" onClick={handleSubmit((data) => {
                    const specs = spec.reduce((acc: { [key: string]: boolean }, s) => { acc[s] = true; return acc; }, {});
                    setRequest({
                        email: email,
                        roles: [roles as ROLE],
                        profile: { ...data, additional: { comment: data.additional, ...specs } },
                        email_verification: secret,
                        lang
                    }).unwrap().then((response) => {
                        if (response.status.code !== 0) {
                            setError(response.status.msg);
                        } else {
                            next();
                        }
                        setRoles('');
                    }).catch((err) => {
                        setError(extractErrorDetail(err));
                    });
                })}>
                    <Trans>modal:SIGN_UP_BUTTON_TEXT</Trans>
                </Button>
            </div>
        </Form>
    );
}

interface SuccessContentProps {
    onClose: () => void
}

function SuccessContent({ onClose }: SuccessContentProps) {
    return (
        <div className="form-message _success">
            <div className="form-message__icon"></div>
            <h2 className="popup__heading form-message__heading"><Trans>modal:REQUEST_SENT</Trans></h2>
            <div className="form-message__copy">
                <div>
                    <Trans>modal:REQUEST_SENT_EXPLAIN</Trans>
                </div>
                <br />
                <div>
                    <Trans>modal:REQUEST_SENT_STATUS</Trans>
                </div>
                <br />
                <div>
                    <Trans>modal:REQUEST_SENT_SUPPORT</Trans>
                </div>
            </div>
            <div className="form-message__ok">
                <span className="btn" onClick={() => onClose()}><Trans>modal:SUCCESS_MESSAGE_CONFIRM</Trans></span>
            </div>
        </div>
    );
}

interface ErrorContentProps {
    content: string,
    onClose: () => void
}

export function ErrorContent({ content, onClose }: ErrorContentProps) {
    const { t } = useTranslation();

    return (
        <div className="form-message _success">
            <div className="form-message" style={{ textAlign: 'center' }}>
                {t('Email already registered') === content ?
                    <></>
                    :
                    <Triangle />
                }
            </div>
            <h2 className="popup__heading form-message__heading" style={{ textAlign: 'center' }}>
                {t('Email already registered') === content ?
                    <></>
                    :
                    <Trans>
                        modal:ERROR_MESSAGE_TITLE
                    </Trans>
                }
            </h2>
            <div className="form-message__copy" style={{ textAlign: 'center' }}>
                {content}
            </div>
            <div className="form-message__ok">
                <span className="btn" onClick={() => onClose()}><Trans>modal:ERROR_MESSAGE_CONFIRM</Trans></span>
            </div>
        </div>
    );
}

interface EmailValidationModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export function EmailValidationModal({ isOpen, onClose }: EmailValidationModalProps) {
    const successClass = 'popup_message';
    const popupClass = 'popup_feedback';

    const [step, setStep] = useState(0);
    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [secret, setSecret] = useState('');
    const [expire, setExpire] = useState<number>(0);
    const [resend, setResend] = useState<number>(0);
    const [token, setToken] = useState('');

    useEffect(() => {
        if (isOpen) {
            setEmail('');
            setError('');
            setStep(STEPS.VERIFY_EMAIL);
        }
    }, [isOpen]);

    function realSetError(err: string) {
        setError(err);
        setStep(STEPS.ERROR);
    }

    let content: JSX.Element;

    switch (step) {
        case STEPS.VERIFY_EMAIL:
            content = <VerifyEmail next={() => setStep(s => s + 1)} token={token} setToken={setToken} setError={realSetError} setEmail={setEmail} setExpire={setExpire} setResend={setResend} />
            break;
        case STEPS.CONFIRM_CODE:
            content = <CodeVerification next={() => setStep(s => s + 1)} token={token} email={email} setSecret={setSecret} setError={realSetError} expire={expire} setExpire={setExpire} setResend={setResend} resend={resend} />
            break;
        case STEPS.REGISTRATION:
            content = <RegistrationForm email={email} secret={secret} next={() => setStep(s => s + 1)} setError={realSetError} />
            break;
        case STEPS.SUCCESS:
            content = <SuccessContent onClose={onClose} />
            break;
        case STEPS.ERROR:
            content = <ErrorContent onClose={onClose} content={error} />
            break;
        default:
            content = <>???</>
    }

    return (
        <Modal className={(step === STEPS.SUCCESS || step === STEPS.ERROR) ? successClass : popupClass} isOpen={isOpen} onClose={() => onClose()}>
            {content}
        </Modal>
    );
}
