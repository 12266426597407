import saveAs from 'file-saver';
import { useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as FileDoc } from '../assets/svg/file-doc.svg';
import { ReactComponent as Filter } from '../assets/svg/filter.svg';
import { ReactComponent as Bell } from '../assets/svg/notification.svg';
import { ReactComponent as Notify } from '../assets/svg/Notify.svg';
import { extractErrorDetail, isConsumer, ymHit } from '../components/common';
import Button from '../components/mplat/Button/Button';
import Content from '../components/mplat/Content';
import ContentShadow from '../components/mplat/ContentShadow';
import { DatePicker } from '../components/mplat/DatePicker';
import MainHeader from '../components/mplat/MainHeader';
import { MobileDateFilterItem } from '../components/mplat/MobileDateFilterItem';
import MobileFilter from '../components/mplat/MobileFilter';
import { MobileFilterItem } from '../components/mplat/MobileFilterItem';
import { ErrorModal } from '../components/mplat/Modals/ErrorModal';
import MultiSelect from '../components/mplat/MultiSelect';
import { PermissionModalModal } from '../components/mplat/OrderContentConsumer';
import { Spinner } from '../components/mplat/Spinner';
import { useRole } from '../hooks';
import { AccessPermissionUnit, useGetMediaHeaderMutation, useGetMetaDataMutation, useLazyGetContentUnitMediaQuery } from '../services/content_v1';
import { NotificationResponse, useLazyGetNotificationsUserQuery } from '../services/notifications';
import { BASE_URL } from '../services/reauth';
import { useGetAccountListQuery } from '../services/users_v1';


export enum NOTIFY_SEVERITY {
    URGENT = '_urgent',
    HIGH = '_high',
    LOW = '_low',
}

export type NotifificationTemplate =
    'content.access.permission.issued.recipient' | // - Предоставлено разрешение (для потребителя)
    'content.access.permission.issued.other' | // Предоставлено разрешение (для партнеров-источников и владельцев контента)
    'content.access.permission.revoked.recipient' | // Разрешение отозвано (для потребителя)
    'content.access.permission.revoked.other' | //  Разрешение отозвано (для партнеров-источников и владельцев контента)
    'content.access.request.media.new' |
    'content.access.request.media.granted' |
    'content.access.request.media.rejected' |
    'content.access.request.tv.new' |
    'content.access.request.tv.rejected' |
    'content.access.request.tv.forwarded' |
    'content.access.request.tv.copied' |
    'content.permission.letter' |
    'content.unit.access.request.new' |
    'content.unit.access.request.granted' |
    'content.unit.access.request.pending' |
    'content.unit.access.request.rejected' |
    'content.unit.new' |
    'content.unit.send_to_user' |
    'moderation.send_report' |
    'moderation.unit_agreed' |
    'moderation.unit.agreed' |
    'moderation.unit_filled' |
    'moderation.unit.filled' |
    'moderation.unit_changed' |
    'moderation.unit.changed' |
    'user.request.new' |
    'content.unit.offer' |
    'feedback.message.new' |
    'support.message.new' |
    'moderation.unit_published' |
    'moderation.unit.published' |
    'user.account.activate' |
    'user.request.existed' |
    'orbox.task.failed' |
    'orbox.check.failed';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMessage(item: NotificationResponse<any>, onDownload: any, highlight?: string, callback?: (id: number) => void): JSX.Element {
    try {
        const template = item.template as NotifificationTemplate;
        switch (template) {
            case 'content.unit.access.request.new':
                return (<>
                    Потребитель
                    {' '}
                    <Link className="link-accent" to={`/subscribers?user=${item.context.request?.sender?.name || item.context.request?.sender?.profile?.company}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request?.sender?.name || item.context.request?.sender?.profile?.company}
                        />
                        »
                    </Link>
                    {' '}
                    запрашивает разрешение на загрузку
                    {' '}
                    {item.context.request.content ?
                        <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={item.context.request.content.title}
                            />
                            »
                        </Link>
                        :
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        item.context.request.units.map((u: any) =>
                            <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={u.title}
                                />
                                »
                            </Link>
                        )
                    }
                </>);
            case 'content.access.request.media.new':
                return (<>
                    Потребитель
                    {' '}
                    <Link className="link-accent" to={`/subscribers/${item.context?.consumer?.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context?.consumer?.name}
                        />
                        »
                    </Link>
                    {' '}
                    запрашивает разрешение на контент
                    {' '}
                    {item.context?.units?.map((u: { id: number, title: string }) =>
                        <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={u.title}
                            />
                            »
                        </Link>
                    )}
                </>);
            case 'content.access.request.tv.new':
                return (<>
                    Потребитель
                    {' '}
                    <Link className="link-accent" to={`/subscribers/${item.context?.consumer?.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context?.consumer?.name}
                        />
                        »
                    </Link>
                    {' '}
                    запрашивает разрешение на телеканалы
                    {' '}
                    {item.context?.units?.map((u: { id: number, title: string }) =>
                        <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={u.title}
                            />
                            »
                        </Link>
                    )}
                </>);
            case 'support.message.new':
                return (<>
                    Сообщение в поддержку: Пользователь
                    {' '}
                    <Link className="link-accent" to={`/accounts/${item.context.request?.sender.account_id}`}>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.request?.sender.account?.name || ''}
                        />
                    </Link>
                    {' '}
                    пишет
                    "{item.context.request?.message}"
                </>);
            case 'feedback.message.new':
                return (<>
                    Получено новое сообщение по форме обратной связи:
                    {' '}
                    "{item.context.request?.message}"
                </>);
            case 'user.request.new':
                return (<>
                    Заявка на регистрацию: <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={`${item.context.profile.lastname} ${item.context.profile.firstname}`}
                        />
                    </strong> компания <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.profile.company}
                        />
                    </strong>
                </>);
            case 'content.access.request.media.granted':
                return (
                    <>
                        Получено разрешение на скачивание
                        {' '}
                        {item.context.units?.map((u: { id: number, title: string }) => <>
                            <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={u.title}
                                />
                                »
                            </Link>
                            <Button size="small" onClick={() => onDownload(u.id, u.title)}>
                                <Trans>Download</Trans>
                            </Button>
                        </>
                        )}
                    </>
                );
            case 'content.unit.access.request.granted':
                return (
                    <>
                        Получено разрешение на скачивание
                        {' '}
                        {item.context.request.content ?
                            <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={item.context.request.content.title}
                                />
                                »
                            </Link>
                            :
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            item.context.request.units.map((u: any) =>
                                <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                                    «
                                    <Highlighter
                                        highlightClassName="search__results-highlight"
                                        searchWords={[highlight || '']}
                                        autoEscape={true}
                                        highlightTag={'span'}
                                        textToHighlight={u.title}
                                    />
                                    »
                                </Link>
                            )
                        }
                        {' '}
                        {item.context.request?.units?.at(0)?.ctype !== 2 &&
                            <Button size="small" onClick={() => onDownload(item.context.request.content.id, item.context.request.content.title)}>
                                <Trans>Download</Trans>
                            </Button>}
                    </>
                );
            case 'content.unit.access.request.pending':
                return (
                    <>
                        Запрос на скачивание
                        {' '}
                        {item.context.request.content ?
                            <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={item.context.request.content.title}
                                />
                                »
                            </Link>
                            :
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            item.context.request.units.map((u: any) =>
                                <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                                    «
                                    <Highlighter
                                        highlightClassName="search__results-highlight"
                                        searchWords={[highlight || '']}
                                        autoEscape={true}
                                        highlightTag={'span'}
                                        textToHighlight={u.title}
                                    />
                                    »
                                </Link>
                            )
                        }
                        {' '}
                        находится на рассмотрении
                    </>
                );
            case 'content.unit.access.request.rejected':
                return (<>
                    Запрос на скачивание
                    {' '}
                    {item.context.request.content ?
                        <Link className="link-accent" to={`/content/view/${item.context.request.content.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={item.context.request.content.title}
                            />
                            »
                        </Link>
                        :
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        item.context.request.units.map((u: any) =>
                            <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={u.title}
                                />
                                »
                            </Link>
                        )
                    }
                    {' '}
                    отклонен
                </>);
            case 'content.access.request.media.rejected':
                return (<>
                    Запрос на контент
                    {' '}
                    {item.context.units.map((u: { id: number, title: string }) =>
                        <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={u.title}
                            />
                            »
                        </Link>
                    )
                    }
                    {' '}
                    отклонен
                </>);
            case 'content.access.request.tv.rejected':
                return (<>
                    Запрос на телеканалы
                    {' '}
                    {item.context.units.map((u: { id: number, title: string }) =>
                        <Link key={u.id} className="link-accent" to={`/content/view/${u.id}`}>
                            «
                            <Highlighter
                                highlightClassName="search__results-highlight"
                                searchWords={[highlight || '']}
                                autoEscape={true}
                                highlightTag={'span'}
                                textToHighlight={u.title}
                            />
                            »
                        </Link>
                    )
                    }
                    {' '}
                    отклонен
                </>);
            case 'content.access.request.tv.copied':
                return (<>
                    Правообладатель {item.context.additional?.sendby?.account?.org?.name || item.context.additional?.sendby?.account?.name} отправил копию <Link to={`/access/${item.context.id}`}>заявки</Link> аккаунту
                    {' '}
                    {item.context.recipient?.org?.name || item.context.recipient?.name}
                </>);
            case 'content.access.request.tv.forwarded':
                return (<>
                    Правообладатель {item.context.additional?.sendby?.account?.org?.name || item.context.additional?.sendby?.account?.name} переадресовал <Link to={`/access/${item.context.id}`}>заявку</Link> аккаунту
                    {' '}
                    {item.context.recipient?.org?.name || item.context.recipient?.name}
                </>);
            case 'content.unit.send_to_user':
                return (<>
                    Поставщик
                    {' '}
                    <Highlighter
                        highlightClassName="search__results-highlight"
                        searchWords={[highlight || '']}
                        autoEscape={true}
                        highlightTag={'span'}
                        textToHighlight={item.context.sender?.name || item.context.sender?.profile?.company}
                    />
                    {' '}
                    открыл Вам доступ на скачивание
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content?.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content?.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_agreed':
            case 'moderation.unit.agreed':
                return (<>
                    Модератор одобрил вашу единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_published':
            case 'moderation.unit.published':
                return (<>
                    Поставщик
                    {' '}
                    <Link className="link-accent" to={`/accounts/view/${item.context.content.owner.id}`}>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.owner.name}
                        />
                    </Link> опубликовал единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_changed':
            case 'moderation.unit.changed':
                return (<>
                    Поставщик
                    {' '}
                    <Highlighter
                        highlightClassName="search__results-highlight"
                        searchWords={[highlight || '']}
                        autoEscape={true}
                        highlightTag={'span'}
                        textToHighlight={item.context.content.owner.company}
                    /> изменил описание единицы контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.unit_filled':
            case 'moderation.unit.filled':
                return (<>
                    Модератор заполнил вашу единицу контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'moderation.send_report':
                return (<>
                    Модератор отправил отчет о единице контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>);
            case 'content.unit.new':
                return (<>
                    Доступен новый контент
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>)
            case 'content.unit.offer':
                return (<>
                    Получено новое предложение
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                </>)
            case 'user.account.activate':
                return (<>
                    Новый пользователь с ролью
                    {' '}
                    <Link className="link-accent" to={'/requests'}>
                        «{item.context.role.ru}»
                    </Link>
                </>)
            case 'orbox.task.failed':
                return (<>
                    Возникла ошибка при проверке медиафайла единицы контента:
                    {' '}
                    {item.context?.orbox?.url &&
                        <>
                            «
                            <Link className="link-accent" to={`/content/view/${item.context.orbox?.url}`}>
                                <Trans>Content</Trans>
                            </Link>
                            »
                        </>}
                    {' '}
                    <strong>
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context?.orbox?.error}
                        />
                    </strong>
                </>)
            case 'orbox.check.failed':
                return (<>
                    Медиафайл единицы контента
                    {' '}
                    <Link className="link-accent" to={`/content/view/${item.context.content.id}`}>
                        «
                        <Highlighter
                            highlightClassName="search__results-highlight"
                            searchWords={[highlight || '']}
                            autoEscape={true}
                            highlightTag={'span'}
                            textToHighlight={item.context.content.title}
                        />
                        »
                    </Link>
                    {' '}
                    не прошел проверку
                </>)
            case 'content.access.permission.issued.recipient':
            case 'content.access.permission.issued.other':
                return (<>
                    Предоставлено
                    {' '}
                    <Link className="link-accent" to={`/access/permissions/${item.context.id}`} onClick={(e) => {
                        e.preventDefault();
                        callback && callback(item.context.id);
                    }}>
                        разрешение
                    </Link>
                    {' '}
                    от <Link className="link-accent" to={`/accounts/${item.context.issuer?.id}`}>{item.context.issuer?.name}</Link>
                    {' '}
                    для <Link className="link-accent" to={`/accounts/${item.context.recipient?.id}`}>{item.context.recipient?.name}</Link>
                    {' '}
                    на контент
                    {' '}
                    {item.context?.units?.map((u: AccessPermissionUnit) => {
                        return (<div key={u.unit.id}>
                            <Link to={`/content/view/${u.unit.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={u.unit.title}
                                />
                                »
                            </Link>
                        </div>)
                    })}
                </>)
            case 'content.access.permission.revoked.recipient':
            case 'content.access.permission.revoked.other':
                return (<>
                    {/* <Link className="link-accent" to={`/access/permissions/${item.context.id}`}> */}
                    Разрешение
                    {/* </Link> */}
                    {' '}
                    на контент
                    {' '}
                    {item.context?.units?.map((u: AccessPermissionUnit) => {
                        return (<div key={u.unit.id}>
                            <Link to={`/content/view/${u.unit.id}`}>
                                «
                                <Highlighter
                                    highlightClassName="search__results-highlight"
                                    searchWords={[highlight || '']}
                                    autoEscape={true}
                                    highlightTag={'span'}
                                    textToHighlight={u.unit.title}
                                />
                                »
                            </Link>
                        </div>)
                    })}
                    отозвано.
                </>);
            case 'content.permission.letter':
                return (<>
                    Отправлено письмо: {item.context.issuer?.org?.name}, является правообладателем указанных ниже телеканалов
                    , и не возражает против получения {item.context.recipient?.org?.name} сигналов телеканалов:
                    {' '}
                    {item.context.units.map((unitinfo: AccessPermissionUnit) =>
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        `${unitinfo.unit.title}`
                    ).join(', ')}
                </>);
            case 'user.request.existed':
                return (<>
                    Попытка регистрации на уже заведенный email ({item.context.email})
                </>)
            default:
                return (<>
                    {JSON.stringify(item)}
                </>)
        }
    } catch (e) {
        console.warn(e);
        const content = JSON.stringify(item);
        return (<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={content}>
            {content}
        </div>)
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getType(item: NotificationResponse<any>) {
    const template = item.template as NotifificationTemplate;
    switch (template) {
        case 'content.unit.new':
        case 'content.unit.access.request.new':
        case 'content.unit.access.request.granted':
        case 'content.unit.send_to_user':
        case 'content.unit.access.request.pending':
        case 'content.access.request.media.new':
        case 'content.access.request.media.granted':
        case 'content.access.request.media.rejected':
        case 'content.access.request.tv.new':
        case 'content.access.request.tv.rejected':
        case 'moderation.send_report':
        case 'moderation.unit_agreed':
        case 'moderation.unit.agreed':
        case 'moderation.unit_filled':
        case 'moderation.unit.filled':
        case 'moderation.unit_changed':
        case 'moderation.unit.changed':
        case 'content.permission.letter':
            return NOTIFY_SEVERITY.LOW;
        case 'content.unit.access.request.rejected':
        case 'user.request.new':
        case 'content.unit.offer':
        case 'feedback.message.new':
        case 'support.message.new':
        case 'moderation.unit_published':
        case 'moderation.unit.published':
        case 'user.account.activate':
        case 'orbox.task.failed':
        case 'orbox.check.failed':
        case 'content.access.permission.issued.recipient':
        case 'content.access.permission.issued.other':
        case 'content.access.permission.revoked.recipient':
        case 'content.access.permission.revoked.other':
        case 'content.access.request.tv.copied':
        case 'content.access.request.tv.forwarded':
        case 'user.request.existed':
            return NOTIFY_SEVERITY.HIGH;
        default:
            return NOTIFY_SEVERITY.HIGH;
    }
}

interface NotificationItemProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: NotificationResponse<any>
    highlight?: string
}

export function NotificationItem({ item, highlight }: NotificationItemProps) {
    const [mediafilesRequest] = useLazyGetContentUnitMediaQuery();
    const [getMetaData] = useGetMetaDataMutation();
    const [getHeader] = useGetMediaHeaderMutation();
    const { t } = useTranslation();

    const [error, setError] = useState<string>('');
    const [errorTitle, setErrorTitle] = useState<string>('');
    const [permIdDialog, setPermIdDialog] = useState(NaN);

    const added = new Date(item.added);
    const H = added.getHours();
    const M = added.getMinutes();
    const HH = H < 10 ? '0' + H.toString() : H.toString();
    const MM = M < 10 ? '0' + M.toString() : M.toString();

    function onDownload(unitId: number, title: string) {
        mediafilesRequest(Number(unitId)).unwrap().then((data) => {
            getMetaData({ id: unitId as number }).unwrap()
                .then((metaData) => {
                    const filename = title;
                    saveAs(metaData, `${filename}.zip`);
                })
                .catch(e => {
                    setErrorTitle(t('Metadata download error'));
                    setError(extractErrorDetail(e));
                })

            if (!!data.results.mediafiles?.length) {
                data.results.mediafiles?.forEach(f => {
                    getHeader(BASE_URL.substring(0, BASE_URL.length - 1) + f.url).then(() => {
                        window.open(BASE_URL.substring(0, BASE_URL.length - 1) + f.url);
                    });
                });
            }
        }).catch((e) => {
            setErrorTitle(t('Get mediafiles error'));
            setError(extractErrorDetail(e));
        });
    }

    return (
        <div className={'notifications__item ' + getType(item)}>
            <ErrorModal isOpen={!!error} onClose={() => setError('')} content={error} title={errorTitle} />
            <PermissionModalModal isOpen={!isNaN(permIdDialog)} permId={permIdDialog} onClose={() => setPermIdDialog(NaN)} />

            <div className="notifications__item-icon">
                <Bell />
            </div>
            <div className="notifications__item-copy" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {getMessage(item, onDownload, highlight, setPermIdDialog)}
            </div>
            <p className="notifications__item-date">
                {added.toLocaleDateString()} · {`${HH}:${MM}`}
            </p>
        </div>
    );
}


function NotificationList() {
    const PAGE_SIZE = 60;

    const [current, setCurrent] = useState(0);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const role = useRole();

    const [date, setDate] = useState<Date | undefined>();
    const [refetch, { data }] = useLazyGetNotificationsUserQuery();
    const { data: clients } = useGetAccountListQuery({});
    // const [fetchTgLink, { data: tgLink }] = useLazyGetNotificationTgBotQuery();

    const { t } = useTranslation();

    useEffect(() => ymHit('/notifications'), []);

    useEffect(() => {
        document.title = t('Notifications');
    }, [t]);

    const [company, setCompany] = useState<number[]>([]);
    const [availCompany, setAvailCompany] = useState<{ value: number, label: string }[]>([]);
    const [notifyTypes, setNotifyTypes] = useState<string[]>([]);

    const [availNotifyTypes] = useState<{ value: string, label: string }[]>([
        { value: NOTIFY_SEVERITY.URGENT, label: 'Срочное' },
        { value: NOTIFY_SEVERITY.HIGH, label: 'Важное' },
        { value: NOTIFY_SEVERITY.LOW, label: 'Обычное' },
    ]);

    useEffect(() => {
        setCurrent(0);
    }, [company, date]);

    useEffect(() => {
        setIsFetched(true);
        refetch({ offset: current, limit: PAGE_SIZE, crdate: (date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`) || '' })
            .then(() => setIsFetched(false))
            .catch(() => setIsFetched(false));
    }, [current, date, refetch]);

    useEffect(() => {
        setHasMore(!!data?.results.next);
    }, [data?.results]);

    const handleNavigation = useCallback(() => {
        if ((document.body.scrollHeight - window.innerHeight) - window.scrollY < 300 && !isFetched) {
            if (hasMore) {
                setCurrent(data?.results.data.length || 0);
            }
        }
    }, [data?.results.data.length, hasMore, isFetched]);

    useEffect(() => {
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        if (clients?.results.data) {
            setAvailCompany(clients.results.data.map((c) => ({ value: c.id, label: c.name })))
        }
        // if (Number(clients?.results.totals.all) <= Number(clients?.results.data.length)) {
        //     const uniqueCompany = new Set<string>();
        //     clients?.results.data.every(s => uniqueCompany.add(s.name));
        //     const result: { value: string, label: string }[] = [];
        //     uniqueCompany.forEach((c) => result.push({ value: c, label: c }));
        //     result.sort((a, b) => {
        //         if (a.value < b.value) {
        //             return -1;
        //         }
        //         if (a.value > b.value) {
        //             return 1;
        //         }
        //         return 0;
        //     });
        //     setAvailCompany(result);
        // }
    }, [clients]);

    const [filterVisible, setFilterVisible] = useState(false);
    const [filterCount, setFilterCount] = useState<number>(0);

    useEffect(() => {
        setFilterCount(
            company.length
            + notifyTypes.length
            + (date ? 1 : 0)
        )
    }, [company, notifyTypes.length, date]);

    return (
        <>
            <Content>
                <ContentShadow />
                <MainHeader label={t('Notifications')} icon={<Notify />} />

                {/* <div style={{ marginBottom: '10px' }}>
                    Подписаться на уведомления в <Link className="link-accent" to="" onClick={() => fetchTgLink()}>Telegram</Link>
                    <br />
                    {tgLink?.results.qrcode && (
                        <>
                            <img src={tgLink.results.qrcode} width={'200px'} />
                            <br />
                            <a className="btn _bd" href={tgLink?.results.url}>Подписаться</a>
                        </>
                    )}
                </div> */}

                <div className="main__content-filter">
                    <div className="main__controls">
                        <div className="main__controls-filter">
                            <div className="main__controls-filter-item">
                                <DatePicker value={date} placeholder="Дата уведомления" setValue={(v) => setDate(v)} />
                            </div>
                            <MultiSelect
                                className="main__controls-filter-item"
                                value={company}
                                options={availCompany}
                                setValue={(v) => setCompany(v)}
                                placeholder={isConsumer(role.label) ? 'Поставщик' : 'Потребитель'}
                                minimized
                            />
                            <MultiSelect className="main__controls-filter-item" value={notifyTypes} options={availNotifyTypes} setValue={(v) => setNotifyTypes(v)} placeholder={'Тип уведомления'} minimized />
                            {/* TODO load into xls */}
                            <a href="#xls" className="main__types-file" target="_blank">
                                <FileDoc />
                                <Trans>Load into xls</Trans>
                            </a>
                        </div>
                        <div className="main__controls-filter-trigger" onClick={() => setFilterVisible(!filterVisible)}>
                            <Filter />
                            {!!filterCount && <span className="main__controls-filter-trigger-counter">{filterCount}</span>}
                        </div>
                    </div>
                </div>

                <div className="notifications">
                    {data?.results.data
                        .filter(item => notifyTypes.length === 0 || notifyTypes.find(notifyType => getType(item) === notifyType))
                        .filter(item => company.length === 0 || company.find(companyName => item.context.request?.receiver?.id === companyName || item.context.request?.sender?.id === companyName || item.context.content?.owner?.id === companyName))
                        .map(item => <NotificationItem key={item.id} item={item} />)}
                    {isFetched && <Spinner />}
                </div>

                <MobileFilter
                    filterVisible={filterVisible}
                    setFilterVisible={setFilterVisible}
                    onClean={() => {
                        setCompany([]);
                        setNotifyTypes([]);
                        setDate(undefined);
                    }}
                >
                    <MobileDateFilterItem value={date} label="Дата уведомления" placeholder="Выберите дату" setValue={(v) => setDate(v)} />
                    <MobileFilterItem label="Тип уведомления" options={availCompany} value={company} setValue={setCompany} />
                    <MobileFilterItem label="Потребитель" options={availNotifyTypes} value={notifyTypes} setValue={setNotifyTypes} />
                </MobileFilter>
            </Content>
        </>
    );
}

export default NotificationList;
