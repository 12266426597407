interface TableHeaderProps {
    children?: React.ReactNode,
}

function TableHeader({ children }: TableHeaderProps) {

    return (
        <div className="table-list__header">
            <div className="table-list__item">
                {children}
            </div>
        </div>
    );
}

export default TableHeader;
