import { TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    // value?: string,
    label?: string,
    dataMessage?: string,
    dataTestId?: string,
}

function TextArea({ value, label, placeholder, required, dataTestId, ...props }: TextAreaProps) {
    return (
        <p className="form__item">
            <label htmlFor="" className="form__label">{label}</label>
            <textarea
                className="form__input _js-autosize"
                data-test-id={dataTestId}
                value={value}
                required={required}
                placeholder={placeholder}
                {...props}
            />
        </p>
    );
}

export default TextArea;