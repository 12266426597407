interface HomeContentProps {
    children?: React.ReactNode,
}

function HomeContent({ children }: HomeContentProps) {
    return (
        <div className="home-content">
            {children}
        </div>
    );
}

export default HomeContent;
