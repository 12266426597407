/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import axios, { AxiosError } from 'axios';
import { EMPTY_ROLE } from '../const';
import { RootState } from '../data/store';
import i18n from '../langs/i18n';
import { setCredentials, setRole } from './api';


const TOKEN_IS_BLACKLISTED = 'Token is blacklisted';
// не обновляем даже accessToken
const NOT_ACCESS_REFRESH = [
    /be\/api\/ui\/summary/,
];
// обновляем accessToken, но не трогаем refreshToken
const NOT_TOKEN_REFRESH = [
    /be\/api\/content\/(d+)\/upload/,
    /be\/api\/content\/uploads/,
    /be\/api\/content\/downloads/,
    /be\/api\/content\/transcoding\/requests/,
];

const getURL = (args: string | FetchArgs) => {
    let url: string;
    if (typeof (args) === 'string') {
        url = args;
    } else {
        url = args.url;
    }
    return url;
}

export const BASE_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8000/';

axios.defaults.baseURL = BASE_URL;

const axiosBaseQuery = async (args: string | FetchArgs & { signal?: any }, api: BaseQueryApi, extraOptions: object) => {
    try {
        const auth = (api.getState() as RootState).auth;
        if (auth.accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`
        } else {
            delete axios.defaults.headers.common.Authorization
        }
        if (i18n.language) {
            axios.defaults.headers.common['Accept-Language'] = i18n.language;
        }
        let result;
        if (typeof (args) === 'string') {
            result = await axios(args);
        } else {
            const { url, method, body, } = args as FetchArgs;
            const responseType = (args as any).responseType;
            const onUploadProgress = (args as any).onUploadProgress;
            const responseDataHandler = (args as any).responseDataHandler;
            const headers = (args as any).headers;
            const signal = (args as any).signal;
            const credentials = (args as any).credentials;
            result = await axios({ url, method, signal, data: body, headers, responseType: responseType, onUploadProgress, withCredentials: !!credentials });
            if (responseDataHandler) {
                const tmp = await responseDataHandler(result.data);
                result = { data: tmp, headers: result.headers };
            }
        }
        return { data: result.data, meta: result.headers };
    } catch (axiosError) {
        const err = axiosError as AxiosError;
        const responseData = err.response?.data instanceof Blob ? await err.response.data.text() : err.response?.data;
        return { error: { status: err.response?.status as number, data: { message: err.message, response: responseData } } };
    }
}

// const baseQuery = fetchBaseQuery({
//     baseUrl: BASE_URL,
//     prepareHeaders: (headers, { getState }) => {
//         const auth = (getState() as RootState).auth;
//         const token = auth.accessToken;

//         // If we have a token set in state, let's assume that we should be passing it.
//         if (token) {
//             headers.set('authorization', `Bearer ${token}`);
//         }
//         return headers
//     },
// });

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // if (localStorage.user === '') {
    //     api.dispatch(setCredentials({ user: '', account: '', accessToken: '', refreshToken: '' }));
    // }
    
    const auth = (api.getState() as RootState).auth;
    let result = await axiosBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401 && auth.refreshToken) {
        if (NOT_ACCESS_REFRESH.some(rx => rx.test(getURL(args)))) {
            return result;
        }

        if ((window as any).sync) {
            await new Promise((r) => setTimeout(r, 250));
            result = await axiosBaseQuery(args, api, extraOptions);
        } else {
            (window as any).sync = true;
            // try to get a new token
            const refreshToken = auth.refreshToken;
            const account = auth.account;
            const refreshResult = await axiosBaseQuery({ url: 'be/token/refresh/', method: 'POST', body: { 'refresh': refreshToken, account } }, api, extraOptions);
            if (refreshResult.data) {
                // store the new token
                if (NOT_TOKEN_REFRESH.some(rx => rx.test(getURL(args)))) {
                    api.dispatch(setCredentials({ user: undefined, accessToken: (refreshResult.data as any)?.access }));
                } else {
                    api.dispatch(setCredentials({ user: undefined, accessToken: (refreshResult.data as any)?.access, refreshToken: ((refreshResult.data as any)?.refresh || auth.refreshToken) }));
                }
                // retry the initial query
                result = await axiosBaseQuery(args, api, extraOptions);
            } else {
                const detail = (refreshResult.error?.data.response as any)?.status.detail || '';
                if (detail !== TOKEN_IS_BLACKLISTED) {
                    api.dispatch(setCredentials({ user: '', account: '', accessToken: '', refreshToken: '' }));
                    api.dispatch(setRole(EMPTY_ROLE));
                } else {
                    if (localStorage.refreshToken === refreshToken) {
                        api.dispatch(setCredentials({ user: '', account: '', accessToken: '', refreshToken: '' }));
                        api.dispatch(setRole(EMPTY_ROLE));
                    }
                }
            }
            (window as any).sync = false;
        }
    } else if (result.error && result.error.status === 401) {
        api.dispatch(setCredentials({ user: '', account: '', accessToken: '', refreshToken: '' }));
    }
    return result;
}

export { baseQueryWithReauth };

