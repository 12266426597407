/* eslint-disable @typescript-eslint/no-explicit-any */

import { CSSProperties } from 'react';

interface SelectButtonProps {
    value: any,
    items: { value: any, label: string, counter?: number }[],
    disabled?: boolean,
    onChange?: (value: any) => void,
    children?: React.ReactNode,
    bubble?: boolean,
    style?: CSSProperties
}

export function SelectButton({ value, items, onChange, disabled, bubble, style, children }: SelectButtonProps) {
    return (
        <div className="main__types" style={style}>
            {items.map(item => <span key={item.value} className={'main__types-item' + (value === item.value ? ' _active' : '')} onClick={() => {
                if (disabled) return;
                onChange && onChange(item.value);
            }}>{item.label} {!!item.counter && <span className={(bubble ? 'label-bubble main__types-item-label' : 'types-item-counter')}>{bubble || '· '}{item.counter}</span>}
            </span>
            )}
            {children}
        </div>
    )
}