import { CSSProperties, ReactNode } from 'react';
import { ReactComponent as Checked } from '../../assets/svg/Checked.svg';

interface CheckBoxProps {
    value: boolean,
    label: ReactNode,
    disabled?: boolean,
    title?: string,
    onChange?: (value: boolean) => void,
    style?: CSSProperties
}

export function CheckBox({ value, label, disabled, onChange, title, style }: CheckBoxProps) {
    return (
        <label className={'checkbox' + (value ? ' checkbox-checked' : '')} style={style} title={title}>
            <input type="checkbox" checked={value} disabled={disabled} onChange={(e) => {
                onChange && onChange(e.target.checked);
            }} /> {label}
            <i>
                <Checked />
            </i>
        </label>
    );
}