/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { AxiosProgressEvent } from 'axios';
import { HISTORY_ROLE } from '../components/common';
import { UploadProcesser } from '../components/downloads/UploadProcesser';
import { OffsetLimit, Profile, StatusPagesResponse, StatusResponse } from './api';
import { baseQueryWithReauth } from './reauth';
import { AccessRequestStatus, AccountListElement, AccountResponse, OrgMinimal, UserProfileMinimal, UserRequest, UserResponse } from './users_v1';

export enum ContentType {
    UNIT = 1,
    TV = 2,
}

export enum LicenseAgreement {
    NO = 1,
    YES = 2,
    PARTIAL = 3,
}

export enum PrefferedSource {
    SATELLITE = 1, //_("CONST-001001", "satellite")
    LAND = 2, //_("CONST-001002", "land")
    MEDIALOGISTIKA = 3, //_("CONST-001003", "medialogistika",)
    OTHER = 4, //_("CONST-001004", "other")
    ANY = 5, //_("CONST-001005", "any")
}

export enum SourcesType {
    SATELLITE = 1,
    LAND = 2,
    MEDIALOGISTIKA = 3,
    OTHER = 4,
    ANY = 5,
}

export enum AccessPermissionStatus {
    WORKING = 0, // "working"
    ISSUED = 1, // "issued"
    REVOKED = 2, // "revoked"
}

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

export type HexColor = IntRange<0, 255>

type oneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type zeroToNine = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type YYYY = `19${zeroToNine}${zeroToNine}` | `20${zeroToNine}${zeroToNine}`
type MM = `0${oneToNine}` | `1${0 | 1 | 2}`
type DD = `${0}${oneToNine}` | `${1 | 2}${zeroToNine}` | `3${0 | 1}`
export type RawDateString = `${YYYY}-${MM}-${DD}`;

export type CONTENT_FIELD =
    // common
    'unit' |
    'qualities' |
    'collections' |
    'languages' |
    'subtitles' |
    'countries' |
    'description' |
    'tags' |
    'covers' |
    'trailers' |
    // tv
    'mmchannel' |
    'mmcertno' |
    'mmowner' |
    'licno' |
    'licowner' |
    'tvthemes' |
    'rowners' |
    'ssources' |
    // unit
    'unit_type' |
    'startyear' |
    'genres' |
    'techinfo' |
    'links' |
    'mediafiles' |
    'rights_area' |
    'rights_cat';

export enum PERMISSIONS {
    CHANGE = 'change',
    DELETE = 'delete',
    VIEW = 'view',
    DOWNLOAD = 'download',
    UPLOAD = 'upload',
    ANY = 'any',
}

export enum TYPS {
    NORMAL = 1, // "normal"
    DRAFT = 2, // "draft"
    DELETED = 4, // "deleted"
    CENSORED = 8, // "censored"
}

export enum ACCESS_TYPES {
    NOBODY = 0, // "nobody"
    ALL = 1, // "all"
    FAVORITES = 2, // "favorites"
    LIST = 3, // "list"
}

export enum UPLOAD_STATE {
    WAITING = 'waiting',
    UPLOADING = 'uploading',
    STOPPED = 'stopped',
    SUCCESS = 'success',
    COMPLETED = 'completed',
    ERROR = 'error',
    BUILDING = 'building',
    FAILED = 'failed',
}

export interface AccessControlEntry {
    permission: PERMISSIONS,
    action: 'allow' | 'deny',
    readonly: boolean,
    user?: { id: number, username: string, company: string },
    group?: { cname: string, name: string },
}

export interface AccessControlEntryRequest {
    permission: PERMISSIONS,
    action: 'allow' | 'deny',
    user?: string,
    group?: string,
}

export interface AccessPermissionUnit {
    fmt: {
        id: number,
        name: string,
    }[],
    unit: {
        id: number,
        ctype: number,
        licno: string,
        owner: number,
        title: string,
        mmcertno: string,
        mmchannel: string,
        covers: string[],
        category: {
            id: number,
            name: string,
        },
    },
    version: string | null
}

export interface AccessPermission {
    id: number,
    status: AccessPermissionStatus,
    validity: {
        lower: string | null,
        upper: string | null,
        bounds: string
    },
    issuer: AccountListElement,
    issuerperson: PersonDescription,
    requestperson: PersonDescription,
    recipient: AccountListElement,
    details: {
        // havelic: false,
        licno: string,
        licdate: string,
        ontest: number,
        sources: { stype: SourcesType, params?: string, satellites: { id: number, name: string }[] }[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additional: any,
    },
    units: AccessPermissionUnit[],
    added: string,
    updated: string,
    accessrequest: { id: number, account_id: number } | null
    issuer_accs: AccountListElement[],
    revoked: string | null,
    issued: string | null,

    // person: PersonDescription,
    // reqperson: PersonDescription,
    // is_favorites: false,
}

export interface AccessPermissionFull extends AccessPermission {
    accessrequest: {
        name: string | null,
        group: number,
        subid: number | null,
        artype: number,
        owner: AccountDescription,
        person: PersonDescription,
        consumer: AccountDescription,
        details: {
            licagr: number,
            ontest: number | null,
            pstype: number,
            psdetails: string,
        },
        added: string,
        updated: string,
        id: number,
        account_id: number,
    } | null,
}

export interface AccessPermissionRequest {
    id?: number,
    issuer: number,
    recipient: number,
    accessrequest?: { id: number, account_id: number },
    grantaccreq?: boolean,
    validity?: { lower?: string, upper?: string },
    details: {
        havelic: boolean,
        licno: string,
        licdate: string | null,
        ontest: number | null,
        sources: { stype: SourcesType, params?: string, satellites?: number[] }[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additional?: any;
    },
    units: { unit: number, fmt: number[], version: string | null }[],
    accs?: number[]
}

export interface AccessResponseOld {
    id: number,
    user: {
        id: number,
        username: string,
        email: string,
        profile: Profile,
        groups?: string[]
    },
    content: number,
    content_title: string,
    content_covers: string[],
    content_owner: {
        id: number,
        username: string,
        email: string,
        profile: Profile
        groups?: string[] // для унификации с user
    },
    messages: [
        {
            user: string,
            message: string,
            timestamp: string,
            status: string,
        }
    ],
    status: AccessRequestStatus
}

export interface AccountDescription {
    id: number,
    rtype: number,
    name: string,
    email: string,
    contact: string,
    phone: string,
    logo: string | null,
    nickname: string | null
}

export interface PersonDescription {
    id: number,
    nickid: string,
    email: string,
    hide_email: boolean,
    firstname: string,
    surname: string | null,
    lastname: string,
    phone: string,
    avatar: string,
    hide_fio: boolean,
    hide_phone: boolean,
}

export interface AccessResponse {
    id: number,
    account_id: number,
    r_type: number,
    name: string | null,
    group: number,
    subid: number | null,
    artype: number,
    units_cnt: number,
    owner: AccountListElement,
    person: PersonDescription
    consumer: AccountListElement,
    provider: AccountListElement,
    recipient: AccountListElement,
    units: {
        id: number,
        ctype: number,
        title: string,
        title_eng: string,
        owner: AccountListElement
        covers: string[]
        category: { id: number, name: string } | null
    }[],
    added: string,
    updated: string,
    status: AccessRequestStatus,
    is_favorites: boolean,
    permissions: {
        id: number,
        status: number,
        validity: {
            lower: string | null,
            upper: string | null,
            bounds: string
        }
    }[],
    additional: {
        sendby: {
            account: AccountListElement,
            status: AccessRequestStatus,
        }
    }
}

export interface AccessFull extends AccessResponse {
    artype: number,
    units_cnt: number,
    iscreated: boolean,
    details: {
        licagr: LicenseAgreement,
        ontest: number | null,
        pstype: SourcesType,
        psdetails: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additional: any
    },
    // request: {
    //     sender: {
    //         accounts: number[]
    //     }
    // },
    // messages: {
    //     user: string,
    //     status: AccessRequestStatus,
    //     message: string,
    //     timestamp: string
    // }[],
    updated: string,
}

export interface AccessMessage {
    id: number,
    added: string,
    sender: AccountListElement,
    person: PersonDescription,
    recipient: AccountListElement,
    message: string,
}

export interface AccessHistory {
    id: number,
    added: string,
    action: number,
    account: AccountListElement,
    person: PersonDescription,
    act_data: {
        recipients: AccountListElement[]
    }
}

export interface AccessSummaryResponse {
    summary: {
        all: number,
        opened: number,
        pending: number,
        granted: number,
        granted_downloaded: number,
        rejected: number,
        canceled: number
    }
}

export interface CheckMark {
    checked: boolean | null,
    updated: string | null,
    user: {
        id: number,
        nickid: string,
        email: string,
        hide_email: false,
        profile: {
            firstname: string,
            surname: string,
            lastname: string,
            phone: string,
            avatar: string | null,
            hide_fio: boolean,
            hide_phone: boolean,
        },
    } | null,
}

export interface Comment {
    id: number,
    fieldname: CONTENT_FIELD,
    comment: string,
    user: {
        id: number,
        username: string,
        email: string,
        profile: Profile,
    },
    added: string
}

export interface Comments {
    qualities: Comment | null,
    collections: Comment | null,
    languages: Comment | null,
    subtitles: Comment | null,
    countries: Comment | null,
    mmchannel: Comment | null,
    mmcertno: Comment | null,
    mmowner: Comment | null,
    licno: Comment | null,
    licowner: Comment | null,
    tvthemes: Comment | null,
    rowners: Comment | null,
    description: Comment | null,
    tags: Comment | null,
    covers: Comment | null,
    trailers: Comment | null,
    ssources: Comment | null,
    unit: Comment | null
}

export interface ContentRequest {
    id?: number,
    title: string,
    title_eng: string | null,
    unit_type?: string,
    collections?: number[],
    startyear?: number,
    genres?: number[],
    countries?: string[],
    qualities?: string[],
    owner?: number // Pick<UserRequest, 'email' | 'username' | 'profile'>
    covers?: { name: string, url: string }[]
    permissions?: PERMISSIONS[],
    view_access: ACCESS_TYPES,
    view_accesslist: number[],
    download_access: ACCESS_TYPES,
    download_accesslist: number[],
    languages: string[],
    subtitles: string[],
    description: string,
    techinfo: string,
    links: { kinopoisk?: string, imdb?: string },
    tags: number[],
    version: number,
    typ?: TYPS,
    offers?: number[],
    autofill?: boolean,
    rights_area: number[],
    rights_cat: number[],
    moderation?: {
        sendreport?: boolean,
        comments?: {
            // title?: string | null,
            unit_type?: string | null,
            qualities?: string | null,
            collections?: string | null,
            startyear?: string | null,
            genres?: string | null,
            languages?: string | null,
            subtitles?: string | null,
            countries?: string | null,
            description?: string | null,
            techinfo?: string | null,
            links?: string | null,
            tags?: string | null,
            covers?: string | null,
            trailers?: string | null,
            rights_area?: string | null,
            rights_cat?: string | null,
            unit?: string | null,
        },
        checkmarks?: {
            unit?: boolean | null,
            title?: boolean | null,
            unit_type?: boolean | null,
            qualities?: boolean | null,
            collections?: boolean | null,
            startyear?: boolean | null,
            genres?: boolean | null,
            languages?: boolean | null,
            subtitles?: boolean | null,
            countries?: boolean | null,
            description?: boolean | null,
            techinfo?: boolean | null,
            links?: boolean | null,
            tags?: boolean | null,
            covers?: boolean | null,
            trailers?: boolean | null,
            rights_area?: boolean | null,
            rights_cat?: boolean | null,
        }
    },
}

export interface ContentTVRequest {
    id?: number,
    title: string,
    title_eng?: string,
    collections?: number[],
    tags?: number[],
    description?: string,
    qualities?: number[],
    languages?: number[],
    countries?: number[],
    subtitles?: number[],
    tvthemes?: number[],
    ptype: number,
    mmchannel?: string,
    mmcertno?: string,
    mmowner: number,
    licno?: string,
    licowner: number,
    category?: number | null,
    bcdelivery?: boolean,
    testperiod?: number,
    ssources?: {
        satellites: number[], // userbooks типа tvsat
        label: string, // уникальная метка, сам придумываешь
        active: boolean,
        stype: number,
        sclass?: number,
        params: string | null
    }[],
    rowners?: number[],
    offers?: number[],
    owner: number,
    view_access?: number,
    view_accesslist?: number[],
    typ?: number,
    version?: number,
    autofill?: boolean,
    moderated?: boolean
    moderation?: {
        sendreport?: boolean,
        comments?: {
            qualities?: string | null,
            collections?: string | null,
            languages?: string | null,
            subtitles?: string | null,
            countries?: string | null,
            description?: string | null,
            tags?: string | null,
            covers?: string | null,
            trailers?: string | null,
            rights_area?: string | null,
            rights_cat?: string | null,
            unit?: string | null,
            mmchannel?: string | null,
            mmcertno?: string | null,
            mmowner?: string | null,
            licno?: string | null,
            licowner?: string | null,
            tvthemes?: string | null,
            rowners?: string | null,
            ssources?: string | null,
        },
        checkmarks?: {
            unit?: boolean | null,
            title?: boolean | null,
            qualities?: boolean | null,
            collections?: boolean | null,
            languages?: boolean | null,
            subtitles?: boolean | null,
            countries?: boolean | null,
            description?: boolean | null,
            tags?: boolean | null,
            covers?: boolean | null,
            trailers?: boolean | null,
            rights_area?: boolean | null,
            rights_cat?: boolean | null,
            mmchannel: boolean | null,
            mmcertno: boolean | null,
            mmowner: boolean | null,
            licno: boolean | null,
            licowner: boolean | null,
            tvthemes: boolean | null,
            rowners: boolean | null,
            [key: string]: boolean | null | undefined,
        }
    },
}

export interface ContentUnit {
    id?: number,
    ctype: number,
    title: string,
    title_eng: string | null,
    covers?: { name: string, url: string }[],
    owner?: AccountResponse, // Pick<UserRequest, 'id' | 'email' | 'username' | 'profile'>
    view_access: ACCESS_TYPES,
    download_access: ACCESS_TYPES,
    permissions?: PERMISSIONS[],
    published: string,
    moderated: boolean,
    autofill: boolean,
    typ: TYPS,
    accreq: { id: number, account_id: number, status: AccessRequestStatus, issuer_id: number, recipient_id: number }[],
    // accperm: { id: number, status: string, issuer_id: number, recipient_id: number }[],
    ar_cnt: number,
    downloads_cnt: number,
    offers: UserRequest[],
    collections?: { id: number, name: string }[],
    tags: { id: number, name: string }[],
    rights_area: { id: number, name: string }[],
    rights_cat: { id: number, name: string }[],
    genres?: { id: string, name: string }[],
    qualities?: { id: string, name: string }[],
    languages: { id: string, name: string }[],
    countries?: { id: string, name: string }[],
    subtitles: { id: string, name: string }[],
    unit_type?: { id: string, name: string },
    startyear?: number,
    techinfo: string,
    links: { kinopoisk?: string, imdb?: string },
    new: boolean,
    offered: boolean,
}

export interface ContentUnitFull extends ContentUnit {
    description: string,
    mediafiles?: MediaFile[]
    trailers?: { name: string, url: string, show_url: string }[]
    view_accesslist?: Partial<UserResponse>[],
    download_accesslist?: Partial<UserResponse>[],
    version: number,
    updated: string,
    moderation: {
        comments: {
            title: Comment | null,
            unit_type: Comment | null,
            qualities: Comment | null,
            collections: Comment | null,
            startyear: Comment | null,
            genres: Comment | null,
            languages: Comment | null,
            subtitles: Comment | null,
            countries: Comment | null,
            description: Comment | null,
            techinfo: Comment | null,
            links: Comment | null,
            tags: Comment | null,
            covers: Comment | null,
            trailers: Comment | null,
            mediafiles: Comment | null,
            rights_area: Comment | null,
            rights_cat: Comment | null,
            unit: Comment | null,
        },
        checkmarks: {
            title: CheckMark,
            unit_type: CheckMark,
            qualities: CheckMark,
            collections: CheckMark,
            startyear: CheckMark,
            genres: CheckMark,
            languages: CheckMark,
            subtitles: CheckMark,
            countries: CheckMark,
            description: CheckMark,
            techinfo: CheckMark,
            links: CheckMark,
            tags: CheckMark,
            covers: CheckMark,
            trailers: CheckMark,
            mediafiles: CheckMark[],
            rights_area: CheckMark,
            rights_cat: CheckMark,
        }
    },
    expire: string | null,
}

export interface ContentTVUnit {
    id: number,
    ctype: number,
    title: string,
    title_eng: string,
    covers?: { name: string, size: number, url: string }[]
    owner: AccountResponse,
    view_access: number,
    download_access: number,
    permissions: PERMISSIONS[],
    published: string,
    moderated: null,
    autofill: boolean,
    typ: number,
    accreq: { id: number, account_id: number, status: AccessRequestStatus, issuer_id: number, recipient_id: number }[],
    // accperm: { id: number, status: AccessRequestStatus, issuer_id: number, recipient_id: number }[],
    active_perm: number | null,
    ar_cnt: number,
    downloads_cnt: number,
    offers: number[],
    collections: { id: number, name: string }[],
    tags: { id: string, name: string }[],
    rights_area: string[],
    rights_cat: string[],
    qualities: { id: number, name: string }[],
    languages: { id: string, name: string }[],
    countries: { id: string, name: string }[],
    subtitles: { id: string, name: string }[],
    tvthemes: { id: string, name: string }[],
    ptype: number,
    mmchannel: string,
    mmcertno: string,
    mmowner: number,
    licno: string,
    licowner: number,
    offered: boolean,
    new: boolean,
    category: { id: number, name: string },
    rowners: number[],
}

export interface ContentTVSource {
    satellites: { id: number, name: string }[] | number[], // userbooks типа tvsat
    label: string, // уникальная метка, сам придумываешь
    active: boolean,
    stype: number,
    sclass: number,
    params: string | null
}

export interface ContentTVUnitFull {
    id: number,
    title: string,
    title_eng: string,
    collections: { id: number, name: string }[],
    tags: { id: number, name: string }[],
    qualities: { id: number, name: string }[],
    languages: { id: number, name: string }[],
    countries: { id: number, name: string }[],
    subtitles: { id: number, name: string }[],
    tvthemes: { id: number, name: string }[],
    ptype: number,
    mmchannel: string | null,
    mmcertno: string | null,
    mmowner: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    } | null,
    licno: string | null,
    licowner: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    } | null,
    category: {
        id: number,
        name: string
    } | null,
    bcdelivery: boolean,
    testperiod: number,
    ssources: ContentTVSource[],
    rowners: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    }[],
    description?: string,
    covers: { name: string, size: number, url: string }[],
    trailers: { name: string, size: number, url: string, show_url: string }[],
    offers: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    }[],
    permissions: PERMISSIONS[],
    owner: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    },
    offered: boolean,
    new: boolean,
    accreq: { id: number, status: AccessRequestStatus, issuer_id: number, recipient_id: number }[] | null,
    // accperm: { id: number, status: AccessRequestStatus, issuer_id: number, recipient_id: number }[],
    active_perm: number | null,
    view_access: number,
    view_accesslist: {
        id: number,
        rtype: number,
        name: string,
        nickname: string | null,
        org: OrgMinimal,
        owner: UserProfileMinimal,
        logo: string | null
    }[],
    typ: number,
    version: number,
    published: string,
    updated: string,
    autofill: boolean,
    moderated: null | boolean,
    expire: string | null,
    moderation: {
        sendreport?: boolean,
        comments: {
            qualities?: Comment | null,
            collections?: Comment | null,
            languages?: Comment | null,
            subtitles?: Comment | null,
            countries?: Comment | null,
            description?: Comment | null,
            tags?: Comment | null,
            covers?: Comment | null,
            trailers?: Comment | null,
            rights_area?: Comment | null,
            rights_cat?: Comment | null,
            unit?: Comment | null,
            mmchannel: Comment | null,
            mmcertno: Comment | null,
            mmowner: Comment | null,
            licno: Comment | null,
            licowner: Comment | null,
            tvthemes: Comment | null,
            rowners: Comment | null,
            ssources: Comment | null,
        },
        checkmarks: {
            unit: CheckMark,
            title: CheckMark,
            qualities: CheckMark,
            collections: CheckMark,
            languages: CheckMark,
            subtitles: CheckMark,
            countries: CheckMark,
            description: CheckMark,
            tags: CheckMark,
            covers: CheckMark,
            trailers: CheckMark,
            rights_area: CheckMark,
            rights_cat: CheckMark,
            mmchannel: CheckMark,
            mmcertno: CheckMark,
            mmowner: CheckMark,
            licno: CheckMark,
            licowner: CheckMark,
            tvthemes: CheckMark,
            rowners: CheckMark,
            [key: string]: CheckMark,
        }
    },
    ctype: number,
}

export interface DownloadInfo {
    id: number,
    created: string,
    file: MediaFile,
    downloaded: boolean,
    percent: number,
    avgrate: number,
    content: {
        id: number,
        title: string,
        title_eng: string,
        owner: {
            username: string,
            email: string,
            profile: Profile,
            id: number
        },
        covers: string[]
    }
}

export type HistoryAction = 'agreed' | 'comment' | 'comment_update' | 'comment_add' | 'comment_delete' | 'cover_upload' | 'cover_delete' | 'create' | 'change' | 'delete' | 'file_upload' | 'file_delete';

export interface History {
    id: number,
    action: HistoryAction,
    added: string,
    role: HISTORY_ROLE,
    username: string,
    usernickid: string,
    acname: string,
    acnickname: string | null,
    attr: CONTENT_FIELD,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ovalue: any | null, // JSON
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nvalue: any | null, // JSON
    hid: string,
    user: number,
    account: number,
    unit: number,
    ctype: number,
}

export interface MediaFile {
    id: number,
    name: string,
    size: number,
    url: string,
    downloaded: boolean,
    transcoded?: MediaFileTranscoded[],
    attributes: {
        orbox?: Orbox,
    }
}

export interface MediaFileTranscoded {
    profile_id: number,
    size: number,
    url: string
}

export interface Orbox {
    passed: boolean,
    report: boolean,
    videocodec: {
        value: string,
        passed: boolean
    },
    resolution: {
        value: string,
        passed: boolean
    },
    check: {
        done: boolean,
        message: string
    }
}

export interface PermissionUnit {
    id: number,
    ctype: number,
    title: string,
    title_eng: string,
    category: {
        id: number,
        name: string
    },
    qualities: {
        id: number,
        name: string
    }[],
    covers: string[],
    rowners: number[],
    ssources: ContentTVSource[]
}

export type PermissionMap = {
    [key in PERMISSIONS]: PermissionMapValue
}

export interface PermissionMapValue {
    users: { id?: number, username?: string, company?: string }[] | string[], groups: { id?: number, groupname?: string, company?: string }[] | string[]
}

export interface ReferenceBooks {
    genre: { id: number, rank: number, label: string, displayname: string }[],
    language: { id: number, rank: number, label: string, displayname: string }[],
    country: { id: number, rank: number, label: string, displayname: string }[],
    utype: { id: number, rank: number, label: string, displayname: string }[],
    // rightsarea: { id: number, rank: number, label: string, displayname: string }[],
    // rightscat: { id: number, rank: number, label: string, displayname: string }[],
    quality: { id: number, rank: number, label: string, displayname: string }[]
    subtitle: { id: number, rank: number, label: string, displayname: string }[]
    tvtheme: { id: number, rank: number, label: string, displayname: string }[]
    tvcategory: { id: number, rank: number, label: string, displayname: string }[]
}

export interface ExtendableListItem {
    id: number,
    sys: boolean,
    rank: number,
    title: {
        ru: string,
        en: string
    }
}

export interface TotalAccessStatus {
    all: number,
    opened: number,
    pending: number,
    granted: number,
    granted_downloaded: number,
    rejected: number,
    canceled: number
}

export interface UsedBooks extends ReferenceBooks {
    collection: string[],
    years: number[],
    tag: string[],
    rightsarea: string[],
    rightscat: string[],
    tvsat: { id: number, rank: number, owner_id: number | null, name: string }[],
}

export interface UserBooks extends ReferenceBooks {
    rightsarea: { id: number, rank: number, owner_id: number, name: string }[],
    rightscat: { id: number, rank: number, owner_id: number, name: string }[],
    collection: { id: number, rank: number, owner_id: number | null, name: string }[],
    years: number[],
    tag: { id: number, rank: number, owner_id: number | null, name: string }[],
    tvsat: { id: number, rank: number, owner_id: number | null, name: string }[],
}

export type UserBooksType = 'genre' | 'language' | 'country' | 'quality' | 'utype' | 'subtitle' | 'tvtheme' | 'tag' | 'collection' | 'rightsarea' | 'rightscat' | 'tvsat' | 'tvcategory';

export interface UserBooksRequest {
    id?: number,
    typ: UserBooksType
    name: string
    rank: number
}

export interface UserBooksResponse {
    id: number,
    typ: UserBooksType,
    owner_id: number,
    name: string,
    rank: number
}

export interface UploadResponse {
    src?: File
    uuid: string,
    unit_id: number,
    content?: {
        id: number,
        title: string,
        title_eng: string,
        owner: Profile,
        covers: string[]
    }
    name: string,
    created?: string,
    updated?: string,
    state: UPLOAD_STATE,
    total_size: number,
    total_chunks: number,
    uploaded_chunks_size: number,
    uploaded_chunks_count: number,
    missed_chunks?: number[][],
    cat?: number,
}

export interface UploadUnitResponse extends UploadResponse {
    orig_name: string,
    missed_chunks: number[][],
    uploaded_size: number,
    uploaded_chunks: number,
    progress: number
}

export interface UnitUpload extends UploadResponse {
    url?: string
    src?: File
    progress: number
    errors: number
    uploadProcesser?: UploadProcesser
    missed_chunks: number[][],
}

const staggeredBaseQuery = retry(baseQueryWithReauth, {
    maxRetries: 0,
})

export const contentApiV1 = createApi({
    reducerPath: 'contentApiV1',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Content', 'Upload', 'Access', 'Media', 'Perm'],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        getContentList: builder.query<StatusPagesResponse<ContentUnit | ContentTVUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentUnitList: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/media/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentUnitListCount: builder.query<StatusResponse<{ count: number }>, string>({
            query: (search) => `be/api/v1/content/media/count/?search=${search}`,
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentOffers: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/${params.content_type || 'tv'}/offers/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            providesTags: () => [{ type: 'Content' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getNewContent: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/${params.content_type || 'tv'}/new/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getCheckedContent: builder.query<StatusPagesResponse<ContentUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/${params.content_type || 'tv'}/moderated/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getUserBook: builder.query<StatusResponse<UserBooks>, void>({
            query: () => 'be/api/v1/content/user_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getContentUserBook: builder.query<StatusResponse<UserBooks>, number>({
            query: (id) => `be/api/v1/content/${id}/user_books/`,
            // providesTags: () => [{ type: 'Content' }],
        }),
        getUsedBook: builder.query<StatusResponse<UsedBooks>, void>({
            query: () => 'be/api/v1/content/used_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        getReferenceBook: builder.query<StatusResponse<ReferenceBooks>, void>({
            query: () => 'be/api/v1/content/reference_books/',
            // providesTags: () => [{ type: 'Content' }],
        }),
        createUserBooksItem: builder.mutation<StatusResponse<UserBooksResponse[]>, UserBooksRequest>({
            query: (data) => ({
                url: 'be/api/v1/content/manage/userbooks/',
                method: 'POST',
                body: data
            })
        }),
        createContentUserBooksItem: builder.mutation<StatusResponse<UserBooksResponse[]>, { content_id: number, data: UserBooksRequest }>({
            query: ({ content_id, data }) => ({
                url: `be/api/v1/content/${content_id}/manage/userbooks/`,
                method: 'POST',
                body: data
            })
        }),
        deleteUserBooksItem: builder.mutation<StatusResponse<null>, number>({
            query: (tag_id) => ({
                url: `be/api/v1/content/manage/userbooks/${tag_id}/`,
                method: 'DELETE',
            })
        }),
        deleteContentUserBooksItem: builder.mutation<StatusResponse<null>, { content_id: number, item_id: UserBooksRequest }>({
            query: ({ content_id, item_id: tag_id }) => ({
                url: `be/api/v1/content/${content_id}/manage/userbooks/${tag_id}/`,
                method: 'DELETE',
            })
        }),
        getContentUnitCollections: builder.query<StatusPagesResponse<{ id: number, name: string }>, number>({
            query: (id: number) => `be/api/v1/content/${id}/collections/`,
            // providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        getContentUnit: builder.query<StatusResponse<ContentUnitFull | ContentTVUnitFull>, number>({
            query: (id: number) => `be/api/v1/content/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        getMediaUnit: builder.query<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => `be/api/v1/content/media/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),

        // История изменений единицы контента
        getContentHistory: builder.query<StatusPagesResponse<History>, number>({
            query: (id: number) => `/be/api/v1/content/${id}/history/`,
        }),
        // /be/api/v1/content/<id>/media/<mediaid>/attributes/orbox/passed/  - [GET] Результаты выполнения тестов Orbox
        getOrboxPassed: builder.query<StatusPagesResponse<History>, { id: number, mediaid: number }>({
            query: (data) => `/be/api/v1/content/${data.id}/media/${data.mediaid}/attributes/orbox/passed/`,
        }),
        // /be/api/v1/content/<id>/media/<mediaid>/attributes/orbox/report/  - [GET] Отчет Orbox
        getOrboxReport: builder.query<StatusPagesResponse<History>, { id: number, mediaid: number }>({
            query: (data) => `/be/api/v1/content/${data.id}/media/${data.mediaid}/attributes/orbox/report/`,
        }),
        // # Модерирование
        // /be/api/v1/content/<id>/moderation/comments/           - [CUD] Создание/удаление/изменение/список комментарием для единицы контента
        getContentComents: builder.query<StatusPagesResponse<Comments>, number>({
            query: (id: number) => `/be/api/v1/content/${id}/moderation/comments/`,
        }),
        // /be/api/v1/content/<id>/moderation/comments/fields     - Список доступных для комментирования полей
        getContentComentsFields: builder.query<StatusResponse<string[]>, number>({
            query: (id: number) => `/be/api/v1/content/${id}/moderation/comments/fields/`,
        }),
        // /be/api/v1/content/<id>/moderation/checkmarks/         - [CU] Список/редактирование галочек модерирования единицы контента
        getContentCheckmarks: builder.query<StatusResponse<{ [key: string]: CheckMark }>, number>({
            query: (id: number) => `/be/api/v1/content/${id}/moderation/checkmarks/`,
        }),
        setContentCheckmarks: builder.mutation<void, { id: number, checks: { [key: string]: boolean } }>({
            query: (data) => ({
                url: `/be/api/v1/content/${data.id}/moderation/checkmarks/`,
                method: 'POST',
                body: data.checks,
            })
        }),
        // /be/api/v1/content/<id>/moderation/checkmarks/fields/  - Список доступных для модерирования полей
        getContentCheckmarksFields: builder.query<StatusResponse<string[]>, number>({
            query: (id: number) => `/be/api/v1/content/${id}/moderation/checkmarks/fields/`,
        }),
        sendContent: builder.mutation<void, { id: number, accounts: string[] }>({
            query: ({ id, accounts }) => ({
                url: `be/api/v1/content/${id}/send/`,
                method: 'POST',
                body: { accounts }
            }),
        }),
        getContentUnitMedia: builder.query<StatusResponse<Pick<ContentUnitFull, 'mediafiles' | 'version' | 'id'>>, number>({
            query: (id: number) => `be/api/v1/content/${id}/`,
            transformResponse: (response: StatusResponse<Pick<ContentUnitFull, 'mediafiles' | 'version' | 'id'>>, _meta, _arg) => {
                const onlyMedia = response.results?.mediafiles;
                const version = response.results?.version;
                const id = response.results?.id;
                const result = { status: response.status, results: { mediafiles: onlyMedia, version, id } };
                return result;
            },
            providesTags: (result, error, id) => [{ type: 'Media', id }],
        }),

        getTVList: builder.query<StatusPagesResponse<ContentTVUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/tv/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentTVListCount: builder.query<StatusResponse<{ count: number }>, string>({
            query: (search) => `be/api/v1/content/tv/count/?search=${search}`,
            providesTags: () => [{ type: 'Content' }],
        }),
        getOffersTV: builder.query<StatusPagesResponse<ContentTVUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/tv/offers/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            providesTags: () => [{ type: 'Content' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getNewTV: builder.query<StatusPagesResponse<ContentTVUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/tv/new/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getCheckedTV: builder.query<StatusPagesResponse<ContentTVUnit>, OffsetLimit>({
            query: (params: OffsetLimit) => {
                return `be/api/v1/content/tv/moderated/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`
            },
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                currentCache.results.next = newItems.results.next;
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
            providesTags: () => [{ type: 'Content' }],
        }),
        getTVUnit: builder.query<StatusResponse<ContentTVUnitFull>, number>({
            query: (id: number) => `be/api/v1/content/tv/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        createTVUnit: builder.mutation<StatusResponse<ContentTVUnit>, Pick<ContentTVRequest, 'title' | 'languages' | 'subtitles' | 'countries'>>({
            query: (data: ContentTVRequest) => ({
                url: 'be/api/v1/content/tv/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),
        createDraftTVUnit: builder.mutation<StatusResponse<ContentTVUnit>, { title?: string, owner: number }>({
            query: ({ title, owner }) => ({
                url: 'be/api/v1/content/tv/',
                method: 'POST',
                body: {
                    title: title || '',
                    title_eng: '',
                    languages: [],
                    subtitles: [],
                    countries: [],
                    description: '',
                    techinfo: '',
                    tags: [],
                    links: {},
                    version: 0,
                    acl: [],
                    view_access: ACCESS_TYPES.NOBODY,
                    view_accesslist: [],
                    download_access: ACCESS_TYPES.NOBODY,
                    download_accesslist: [],
                    typ: TYPS.DRAFT,
                    owner: owner
                }
            }),
        }),
        editTVUnit: builder.mutation<StatusResponse<ContentTVUnit>, Partial<ContentTVRequest>>({
            query: (data: ContentTVRequest) => ({
                url: `be/api/v1/content/tv/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Content' }, { type: 'Content', id: result?.results.id }]
        }),
        deleteTVUnit: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/v1/content/tv/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),

        createContentUnit: builder.mutation<StatusResponse<ContentUnitFull>, Pick<ContentRequest, 'title' | 'genres' | 'languages' | 'subtitles' | 'countries'>>({
            query: (data: ContentRequest) => ({
                url: 'be/api/v1/content/media/',
                method: 'POST',
                body: data
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),
        createDraftUnit: builder.mutation<StatusResponse<ContentUnitFull>, { title?: string, owner: number }>({
            query: ({ title, owner }) => ({
                url: 'be/api/v1/content/media/',
                method: 'POST',
                body: {
                    title: title || '',
                    title_eng: '',
                    languages: [],
                    subtitles: [],
                    countries: [],
                    description: '',
                    techinfo: '',
                    tags: [],
                    links: {},
                    version: 0,
                    acl: [],
                    view_access: ACCESS_TYPES.NOBODY,
                    view_accesslist: [],
                    download_access: ACCESS_TYPES.NOBODY,
                    download_accesslist: [],
                    typ: TYPS.DRAFT,
                    owner: owner
                }
            }),
        }),
        makeCensoredUnit: builder.mutation<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => ({
                url: `be/api/v1/content/${id}/`,
                method: 'PATCH',
                body: {
                    typ: TYPS.CENSORED,
                }
            }),
        }),
        unmakeCensoredUnit: builder.mutation<StatusResponse<ContentUnitFull>, number>({
            query: (id: number) => ({
                url: `be/api/v1/content/${id}/`,
                method: 'PATCH',
                body: {
                    typ: TYPS.NORMAL,
                }
            }),
        }),
        editContentUnit: builder.mutation<StatusResponse<ContentUnitFull>, Partial<ContentRequest>>({
            query: (data: ContentRequest) => ({
                url: `be/api/v1/content/media/${data.id}/`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Content' }, { type: 'Content', id: result?.results.id }]
        }),
        deleteContentUnit: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `be/api/v1/content/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Content' }]
        }),
        getUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, void>({
            query: () => 'be/api/v1/content/uploads/',
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Upload' }],
        }),
        getUnitUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, number>({
            query: (id: number) => `be/api/v1/content/${id}/upload/?cat=1`,
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Upload', id }],
            transformResponse: (response: StatusResponse<{ data: UploadResponse[] }>, meta, arg) => {
                return { status: response.status, results: { data: response.results.data.filter(u => u.cat === 1) } };
            },
        }),
        getUnitTrailerUploads: builder.query<StatusResponse<{ data: UploadResponse[] }>, number>({
            query: (id: number) => `be/api/v1/content/${id}/upload/?cat=3`,
            transformResponse: (response: StatusResponse<{ data: UploadResponse[] }>, meta, arg) => {
                return { status: response.status, results: { data: response.results.data.filter(u => u.cat === 3) } };
            },
        }),
        getUnitUpload: builder.query<StatusResponse<UploadUnitResponse>, { id: number, uuid: string }>({
            query: (args) => `be/api/v1/content/${args.id}/upload/${args.uuid}/`,
            providesTags: (result, error, args) => [{ type: 'Content' }, { type: 'Upload', id: args.id }],
            transformResponse: (response: StatusResponse<UploadUnitResponse>, meta, arg) => {
                const result = { ...response.results, progress: response.results.uploaded_chunks_count / response.results.total_chunks * 100 };
                return { status: response.status, results: result };
            },
        }),
        deleteUnitUpload: builder.mutation<StatusResponse<UploadUnitResponse>, { id: number, uuid: string }>({
            query: (args) => ({
                url: `be/api/v1/content/${args.id}/upload/${args.uuid}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Upload' }]
        }),
        getMediaHeader: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'HEAD',
                credentials: 'include',
            }),
        }),
        uploadContentMediaChunk: builder.mutation<StatusResponse<UploadResponse>, { id: number, url: string, data: Buffer, signal?: AbortSignal }>({
            query: (args) => ({
                url: args.url,
                method: 'POST',
                body: args.data,
                headers: { 'Content-Type': 'application/octet-stream' },
                signal: args.signal,
            }),
        }),
        deleteContentMedia: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Media' }]
        }),
        createContentTrailer: builder.mutation<void, { id: number, file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `be/api/v1/content/${data.id}/trailer/`,
                    method: 'POST',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    }
                };
            },
        }),
        deleteContentTrailer: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
        }),
        createContentCover: builder.mutation<void, { id: number, file: File, onProgress?: (e: AxiosProgressEvent) => void }>({
            query(data) {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `be/api/v1/content/${data.id}/cover/`,
                    method: 'POST',
                    body: formData,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        data.onProgress && data.onProgress(progressEvent)
                    },
                };
            },
        }),
        deleteContentCover: builder.mutation<void, string>({
            query: (url) => ({
                url: url,
                method: 'DELETE',
            }),
        }),
        getMetaData: builder.mutation<string, { taskid?: string, id: number }>({
            query: (data) => ({
                url: `be/api/v1/content/${data.id}/metadata/`,
                method: 'POST',
                body: { taskid: data.taskid, inline: false },
                responseType: 'blob',
                responseDataHandler: async (blobData: BlobPart) => {
                    const blob = new Blob([blobData]);
                    return URL.createObjectURL(blob);
                },
            }),
        }),
        getDowloadInfo: builder.query<StatusResponse<DownloadInfo[]>, void>({
            query: () => 'be/api/v1/content/downloads/',
            providesTags: () => [{ type: 'Content' }],
        }),
        getContentDownloadInfo: builder.query<StatusResponse<DownloadInfo[]>, number>({
            query: (id: number) => `be/api/v1/content/${id}/downloads/`,
            providesTags: (result, error, id) => [{ type: 'Content', id }],
        }),
        accessRequestTV: builder.mutation<StatusResponse<AccessResponse>, {
            units: number[],
            sender: number,
            details: {
                licagr: number | null,
                pstype: number | null,
                psdetails?: string,
                additional: string,
                ontest: number | null
            },
            message?: string
        }>({
            query: ({ units, sender, details, message }) => ({
                url: 'be/api/v1/content/access/requests/',
                method: 'POST',
                body: { units: units, sender: sender, details: details, message: message || 'Empty message' }
            }),
            invalidatesTags: (result, error, data) => [{ type: 'Access' }, { type: 'Content' }]
        }),
        accessRequestUnit: builder.mutation<StatusResponse<AccessResponse>, { unit: number, sender: number, message?: string }>({
            query: (data) => ({
                url: 'be/api/content/access/requests/',
                method: 'POST',
                body: { units: [data.unit], sender: data.sender, message: data.message || 'Empty message' }
            }),
            invalidatesTags: (result, error, data) => [{ type: 'Access' }, { type: 'Content' }, { type: 'Content', id: data.unit }]
        }),
        getAccessRequest: builder.query<StatusResponse<AccessFull>, { ar_id: number, acc_id: number }>({
            query: ({ ar_id, acc_id }) => `be/api/v1/content/access/requests/${ar_id}/${acc_id}/`,
            providesTags: (result, error, params) => [{ type: 'Access', id: params.ar_id }],
        }),
        getAccessRequestMessage: builder.query<StatusResponse<AccessMessage[]>, { ar_id: number, acc_id: number }>({
            query: ({ ar_id, acc_id }) => `be/api/v1/content/access/requests/${ar_id}/${acc_id}/message/`,
            providesTags: (result, error, params) => [{ type: 'Access', id: params.ar_id }],
        }),
        getAccessRequestMessageRecipients: builder.query<StatusResponse<AccountListElement[]>, { ar_id: number, acc_id: number }>({
            query: ({ ar_id, acc_id }) => `be/api/v1/content/access/requests/${ar_id}/${acc_id}/message/recipients/`,
            providesTags: (result, error, params) => [{ type: 'Access', id: params.ar_id }],
        }),
        getAccessRequestAllowedRecipients: builder.query<StatusResponse<AccountListElement[]>, { ar_id: number, acc_id: number }>({
            // Список аккаунтов, которым можно переслать заявку
            query: ({ ar_id, acc_id }) => `be/api/v1/content/access/requests/${ar_id}/${acc_id}/allowed/recipients/`,
            providesTags: (result, error, params) => [{ type: 'Access', id: params.ar_id }],
        }),
        getAccessRequestHistory: builder.query<StatusResponse<AccessHistory[]>, { ar_id: number, acc_id: number }>({
            // История заявки
            query: ({ ar_id, acc_id }) => `be/api/v1/content/access/requests/${ar_id}/${acc_id}/history/`,
            providesTags: (result, error, params) => [{ type: 'Access', id: params.ar_id }],
        }),
        getAccessRequests: builder.query<StatusPagesResponse<AccessResponse> & { results: { totals: TotalAccessStatus } }, OffsetLimit>({
            query: (params: OffsetLimit) => `be/api/v1/content/access/requests/?${Object.keys(params).filter(k => params[k] || params[k] === 0).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: (result, error, id) => [{ type: 'Access' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getAccessRequestsSummary: builder.query<StatusResponse<AccessSummaryResponse>, void>({
            query: () => 'be/api/v1/content/access/requests/summary/',
            providesTags: (result, error, id) => [{ type: 'Access' }],
        }),
        accessRequestsSetStatus: builder.mutation<StatusResponse<AccessResponse>, { ar_id: number, acc_id: number, status: AccessRequestStatus, sendto?: number }>({
            query: ({ ar_id, acc_id, status, sendto }) => ({
                url: `be/api/v1/content/access/requests/${ar_id}/${acc_id}/`,
                method: 'PUT',
                body: {
                    status,
                    sendto,
                }
            }),
            invalidatesTags: (result, error, p) => [{ type: 'Access' }, { type: 'Access', id: p.ar_id }]
        }),

        getCover: builder.mutation<string, string>({
            query: (url) => ({
                url: url,
                responseType: 'blob',
                responseDataHandler: async (data: BlobPart) => {
                    const blob = new Blob([data], { type: 'image/jpg' })
                    return URL.createObjectURL(blob);
                },
                cache: 'no-cache',
            }),
        }),
        getAccessPermissions: builder.query<StatusPagesResponse<AccessPermission>, OffsetLimit>({
            query: (params: OffsetLimit) => `be/api/v1/content/access/permissions/?${Object.keys(params).filter(k => params[k]).map(k => params[k].toString().split(',').map(mk => k + '=' + mk).join('&')).join('&')}`,
            providesTags: (result, error, id) => [{ type: 'Perm' }],
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                const end = endpointName + JSON.stringify(Object.entries(queryArgs).filter(([k, v]) => !['offset', 'limit'].includes(k) && !!v));
                return end;
            },
            // // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg.offset === 0) {
                    currentCache.results.data = newItems.results.data;
                } else {
                    currentCache.results.data.push(...newItems.results.data)
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg
            },
        }),
        getAccessPermission: builder.query<StatusResponse<AccessPermissionFull>, number>({
            query: (perm_id) => `be/api/v1/content/access/permissions/${perm_id}/`,
            providesTags: (result, error, id) => [{ type: 'Perm' }],
        }),
        getAccessPermissionPdf: builder.query<string, number>({
            query: (perm_id) => ({
                url: `be/api/v1/content/access/permissions/${perm_id}/pdf/`,
                responseType: 'blob',
                responseDataHandler: async (data: BlobPart) => {
                    const blob = new Blob([data], { type: 'application/pdf' })
                    return URL.createObjectURL(blob);
                },
                cache: 'no-cache',
            }),
            // query: (perm_id) => `be/api/v1/content/access/permissions/${perm_id}/pdf/`,
        }),
        createAccessPermission: builder.mutation<StatusResponse<AccessPermission>, AccessPermissionRequest>({
            query: (data) => ({
                url: 'be/api/v1/content/access/permissions/',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Perm' }, { type: 'Access' }, { type: 'Perm', id: result?.results.id }]
        }),
        editAccessPermission: builder.mutation<StatusResponse<AccessPermission>, Partial<AccessPermissionRequest>>({
            query: (data: AccessPermissionRequest) => ({
                url: `be/api/v1/content/access/permissions/${data.id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Perm' }, { type: 'Perm', id: result?.results.id }]
        }),
        updateAccessPermissionStatus: builder.mutation<StatusResponse<AccessPermission>, { id: number, status: AccessPermissionStatus, updated?: string }>({
            query: (data) => ({
                url: `be/api/v1/content/access/permissions/${data.id}/status/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Perm' }, { type: 'Perm', id: result?.results.id }]
        }),
        getPermissionAvailUnits: builder.query<StatusPagesResponse<PermissionUnit>, void>({
            query: () => 'be/api/v1/content/access/permissions/available/units/?offset=0&limit=-1',
            providesTags: (result, error, id) => [{ type: 'Perm' }],
        }),
    }),
})

export const {
    useAccessRequestsSetStatusMutation,
    useAccessRequestTVMutation,
    useAccessRequestUnitMutation,
    useCreateAccessPermissionMutation,
    useCreateContentCoverMutation,
    useCreateContentTrailerMutation,
    useCreateContentUnitMutation,
    useCreateContentUserBooksItemMutation,
    useCreateDraftTVUnitMutation,
    useCreateDraftUnitMutation,
    useCreateTVUnitMutation,
    useCreateUserBooksItemMutation,
    useDeleteContentCoverMutation,
    useDeleteContentMediaMutation,
    useDeleteContentTrailerMutation,
    useDeleteContentUnitMutation,
    useDeleteContentUserBooksItemMutation,
    useDeleteTVUnitMutation,
    useDeleteUnitUploadMutation,
    useDeleteUserBooksItemMutation,
    useEditAccessPermissionMutation,
    useEditContentUnitMutation,
    useEditTVUnitMutation,
    useGetAccessPermissionQuery,
    useGetAccessPermissionPdfQuery,
    useGetAccessPermissionsQuery,
    useGetAccessRequestAllowedRecipientsQuery,
    useGetAccessRequestHistoryQuery,
    useGetAccessRequestMessageQuery,
    useGetAccessRequestMessageRecipientsQuery,
    useGetAccessRequestQuery,
    useGetAccessRequestsQuery,
    useGetAccessRequestsSummaryQuery,
    useGetCheckedContentQuery,
    useGetCheckedTVQuery,
    useGetContentCheckmarksFieldsQuery,
    useGetContentCheckmarksQuery,
    useGetContentComentsFieldsQuery,
    useGetContentComentsQuery,
    useGetContentDownloadInfoQuery,
    useGetContentHistoryQuery,
    useGetContentListQuery,
    useGetContentOffersQuery,
    useGetContentTVListCountQuery,
    useGetContentUnitCollectionsQuery,
    useGetContentUnitListCountQuery,
    useGetContentUnitListQuery,
    useGetContentUnitMediaQuery,
    useGetContentUnitQuery,
    useGetContentUserBookQuery,
    useGetCoverMutation,
    useGetDowloadInfoQuery,
    useGetMediaHeaderMutation,
    useGetMediaUnitQuery,
    useGetMetaDataMutation,
    useGetNewContentQuery,
    useGetNewTVQuery,
    useGetOffersTVQuery,
    useGetOrboxPassedQuery,
    useGetOrboxReportQuery,
    useGetPermissionAvailUnitsQuery,
    useGetReferenceBookQuery,
    useGetTVListQuery,
    useGetTVUnitQuery,
    useGetUnitTrailerUploadsQuery,
    useGetUnitUploadQuery,
    useGetUnitUploadsQuery,
    useGetUploadsQuery,
    useGetUsedBookQuery,
    useGetUserBookQuery,
    useLazyGetAccessPermissionQuery,
    useLazyGetAccessPermissionPdfQuery,
    useLazyGetAccessPermissionsQuery,
    useLazyGetAccessRequestAllowedRecipientsQuery,
    useLazyGetAccessRequestHistoryQuery,
    useLazyGetAccessRequestMessageQuery,
    useLazyGetAccessRequestMessageRecipientsQuery,
    useLazyGetAccessRequestQuery,
    useLazyGetAccessRequestsQuery,
    useLazyGetAccessRequestsSummaryQuery,
    useLazyGetCheckedContentQuery,
    useLazyGetCheckedTVQuery,
    useLazyGetContentCheckmarksFieldsQuery,
    useLazyGetContentCheckmarksQuery,
    useLazyGetContentComentsFieldsQuery,
    useLazyGetContentComentsQuery,
    useLazyGetContentDownloadInfoQuery,
    useLazyGetContentHistoryQuery,
    useLazyGetContentListQuery,
    useLazyGetContentOffersQuery,
    useLazyGetContentTVListCountQuery,
    useLazyGetContentUnitCollectionsQuery,
    useLazyGetContentUnitListCountQuery,
    useLazyGetContentUnitListQuery,
    useLazyGetContentUnitMediaQuery,
    useLazyGetContentUnitQuery,
    useLazyGetContentUserBookQuery,
    useLazyGetDowloadInfoQuery,
    useLazyGetMediaUnitQuery,
    useLazyGetNewContentQuery,
    useLazyGetNewTVQuery,
    useLazyGetOffersTVQuery,
    useLazyGetOrboxPassedQuery,
    useLazyGetOrboxReportQuery,
    useLazyGetPermissionAvailUnitsQuery,
    useLazyGetReferenceBookQuery,
    useLazyGetTVListQuery,
    useLazyGetTVUnitQuery,
    useLazyGetUnitTrailerUploadsQuery,
    useLazyGetUnitUploadQuery,
    useLazyGetUnitUploadsQuery,
    useLazyGetUploadsQuery,
    useLazyGetUsedBookQuery,
    useLazyGetUserBookQuery,
    useMakeCensoredUnitMutation,
    useSendContentMutation,
    useSetContentCheckmarksMutation,
    useUnmakeCensoredUnitMutation,
    useUpdateAccessPermissionStatusMutation,
    useUploadContentMediaChunkMutation,
} = contentApiV1;
