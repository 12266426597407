import { HTMLAttributes } from 'react';

interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    children?: React.ReactNode,
}

function TableRow({ className, children, ...props }: TableRowProps) {
    return (
        <div
            className={'table-list__item ' + (className || '')}
            {...props}
        >
            {children}
        </div>
    );
}

export default TableRow;
