import { ReactComponent as ArrowDown } from '../../../assets/svg/ArrowDown.svg';

interface TableHeaderColumnProps {
    field?: string,
    header: string | JSX.Element,
    ordering?: string,
    title?: string,
    setOrdering?: (value: string) => void,
    className?: string
}

export function TableHeaderColumn({ ordering, setOrdering, field, header, className, title }: TableHeaderColumnProps) {
    return (
        <div className={'table-list__col ' + (className || '')}>
            {field ?
                <span className={'table-list__header-label' + (ordering === `-${field}` ? ' _asc' : '')} title={title} onClick={() => {
                    setOrdering && setOrdering(ordering === field ? `-${field}` : `${field}`);
                }}>
                    {header}{ordering?.indexOf(field) !== -1 && <ArrowDown className="table-list__header-label-icon" />}
                </span>
                :
                header
            }
        </div>
    );
}