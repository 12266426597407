import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logout } from '../assets/svg/logout.svg';
import { extractErrorDetail, ROLE, useIsProvider } from '../components/common';
import Button from '../components/mplat/Button/Button';
import { CheckBox } from '../components/mplat/CheckBox';
import Content from '../components/mplat/Content';
import Form from '../components/mplat/Form';
import Header1 from '../components/mplat/Header1';
import { useAuth, useBodyClass, useRole } from '../hooks';
import { useGetProfileQuery, useLazyGetAccountAgreementQuery, useLazyGetCurrentAccountQuery, useUpdateAccountAgreementMutation, useUpdateUserProfileAgreementMutation } from '../services/users_v1';
import NotFoundPage from './NotFoundPage';


function AgreementPage() {
    const auth = useAuth();
    const role = useRole();
    const isProvider = useIsProvider();
    const dispatch = useDispatch();

    const { data: profile, error: profileError } = useGetProfileQuery(undefined);
    const [fetchAccountAgreement, { data: accountAgreement }] = useLazyGetAccountAgreementQuery();
    const [fetchUpdateProfileAgreement] = useUpdateUserProfileAgreementMutation();
    const [fetchUpdateAccountAgreement] = useUpdateAccountAgreementMutation();
    const [fetchUserAccount] = useLazyGetCurrentAccountQuery();
    // const [accountLogin] = useAccountLoginMutation();
    const navigate = useNavigate();

    const [agree, setAgree] = useState(false);
    const [bodyClasses, setBodyClasses] = useState<string[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (profile?.results.pd_agreement) {
            fetchAccountAgreement();
        }
    }, [profile]);

    useEffect(() => {
        const tmp: string[] = [];
        if (auth.account) {
            if (isProvider || role.label === ROLE.SITE_ADMIN) {
                tmp.push('page-producer');
            } else {
                tmp.push('page-subscriber');
            }
        } else {
            tmp.push('page-subscriber');
        }
        setBodyClasses(tmp);
    }, [auth.account, auth.user, dispatch, role.label]);

    useBodyClass(bodyClasses);

    let content = <NotFoundPage />;
    if (accountAgreement?.results.agr_offer) {
        content = <NotFoundPage />
    } else if (profile?.results.pd_agreement) {
        content = <Content>
            <div className="content__side-btn">
                <Link to="/logout" title="Выйти">
                    <Logout />
                </Link>
            </div>
            <div className="user-settings">
                <Header1>
                    <Trans>Confirmation</Trans>
                </Header1>

                <Form>
                    <p>
                        Пожалуйста, подтвердите согласие с
                        {' '}
                        <a className="link-accent" href="https://media.msk-ix.ru/download/legal/ru/ServiceAgreement_Mediabaza.pdf" target="_blank" rel="noreferrer">
                            пользовательским соглашением
                        </a>
                        {' '}
                        для дальнейшей работы с ресурсами Медиабазы.
                    </p>
                    <div className="form__item">
                        <CheckBox value={agree} label={t('Согласен')} onChange={(v) => setAgree(v)} />
                    </div>

                    <div className="user-settings__list-item" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} >
                        {error && <><br />{error}</>}
                        {profileError && <><br />{extractErrorDetail(profileError)}</>}
                    </div>
                    <div className="form__submit">
                        <Button disabled={!agree} onClick={() => {
                            fetchUpdateAccountAgreement().unwrap().then(() => {
                                fetchUserAccount();
                                window.location.pathname === '/agreement' && navigate('/');
                            }).catch((e) => {
                                setError(e.message);
                            });
                        }}>
                            <Trans>Confirm</Trans>
                        </Button>
                    </div>
                </Form>
            </div>
        </Content>
    } else {
        content = <Content>
            <div className="content__side-btn">
                <Link to="/logout" title="Выйти">
                    <Logout />
                </Link>
            </div>
            <div className="user-settings">
                <Header1>
                    <Trans>Confirmation</Trans>
                </Header1>

                <Form>
                    <p>
                        Пожалуйста, подтвердите
                        {' '}
                        <a className="link-accent" href="https://media.msk-ix.ru/download/legal/ru/pd-consent_MSK-IX.pdf" target="_blank" rel="noreferrer">
                            согласие на обработку персональных данных
                        </a>
                        {' '}
                        для дальнейщей работы с ресурсами Медиабазы.
                    </p>
                    <div className="form__item">
                        <CheckBox value={agree} label={t('Согласен')} onChange={(v) => setAgree(v)} />
                    </div>
                    <div className="user-settings__list-item" style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'center' }} >
                        {error && <><br />{error}</>}
                        {profileError && <><br />{extractErrorDetail(profileError)}</>}
                    </div>
                    <div className="form__submit">
                        <Button disabled={!agree} onClick={() => {
                            fetchUpdateProfileAgreement().unwrap().then(() => {
                                navigate('/');
                            }).catch((e) => {
                                setError(e.message);
                            });
                        }}>
                            <Trans>Confirm</Trans>
                        </Button>
                    </div>
                </Form>
            </div>
        </Content>
    }

    return content;
}

export default AgreementPage;
