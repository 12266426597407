import { UnitUpload, UploadResponse, UPLOAD_STATE } from '../../services/content_v1';
import { UploadProcesser } from './UploadProcesser';

export function collectUploads(localUploads: { [key: string]: UnitUpload }, remoteUploads?: UploadResponse[], unitId?: number, cat?: number): { [key: string]: UnitUpload } {
    const tmp: { [key: string]: UnitUpload } = {};
    const category = cat ? cat : 1;

    if (unitId !== undefined && isNaN(unitId)) return tmp;

    remoteUploads?.filter(unit => category === -1 || unit.cat === category).map(unit => tmp[unit.uuid] = { ...unit as UnitUpload, progress: unit.uploaded_chunks_count / unit.total_chunks * 100 });

    Object.values(localUploads).filter(unit => category === -1 || unit.cat === category).forEach(unit => {
        if (tmp[unit.uuid] &&
            (
                tmp[unit.uuid].state === UPLOAD_STATE.ERROR
                || tmp[unit.uuid].state === UPLOAD_STATE.FAILED
                || tmp[unit.uuid].state === UPLOAD_STATE.BUILDING
            )
        ) {
            const progress = (tmp[unit.uuid]?.progress || 0) > unit.progress ? tmp[unit.uuid].progress : unit.progress;
            tmp[unit.uuid] = { ...unit, ...tmp[unit.uuid], progress };
        } else {
            if (unit.state === UPLOAD_STATE.COMPLETED || unit.state === UPLOAD_STATE.SUCCESS) {
                if (!tmp[unit.uuid] || tmp[unit.uuid].state !== UPLOAD_STATE.UPLOADING) {
                    delete tmp[unit.uuid];
                } else {
                    const progress = (tmp[unit.uuid]?.progress || 0) > unit.progress ? tmp[unit.uuid].progress : unit.progress;
                    tmp[unit.uuid] = { ...tmp[unit.uuid], ...unit, progress };
                }
            } else {
                if (unitId === undefined || unit.unit_id === unitId) {
                    const progress = (tmp[unit.uuid]?.progress || 0) > unit.progress ? tmp[unit.uuid].progress : unit.progress;
                    tmp[unit.uuid] = { ...tmp[unit.uuid], ...unit, progress };
                } else {
                    delete tmp[unit.uuid];
                }
            }
        }
    });

    return tmp;
}

export function getInitialUploadState(uploadStatus: UnitUpload): UnitUpload {
    const unit_id = uploadStatus.unit_id;
    const uuid = uploadStatus.uuid;
    const uploaded_chunks_size = uploadStatus.uploaded_chunks_size;
    const total_size = uploadStatus.total_size;
    const uploaded_chunks_count = uploadStatus.uploaded_chunks_count;
    const total_chunks = uploadStatus.total_chunks;
    const name = uploadStatus.name;
    const state = uploadStatus.state;
    const progress = Math.floor(uploaded_chunks_count / (total_chunks || 1));
    const missed_chunks: number[][] = [];

    const unitUpload = {
        src: undefined,
        unit_id,
        uuid,
        name,
        state,
        progress,
        errors: 0,
        total_size,
        total_chunks,
        uploaded_chunks_size,
        uploaded_chunks_count,
        missed_chunks,
    };
    return unitUpload;
}

export function getUploadStateFromProcesser(unit_id: number, processer: UploadProcesser): UnitUpload {
    const total_chunks = Math.ceil((processer.src?.size || 0) / processer.config.chunkSize);

    const unitUpload = {
        src: (processer.status !== UPLOAD_STATE.ERROR && processer.status !== UPLOAD_STATE.FAILED) ? processer.src : undefined,
        uuid: processer.uuid,
        name: processer.name || '',
        unit_id,
        state: processer.status,
        progress: processer.progress,
        errors: processer.errors,
        total_size: processer.src?.size || 0,
        total_chunks,
        uploaded_chunks_size: 0,
        uploaded_chunks_count: processer.uploadedChunks,
        uploadProcesser: processer,
        cat: processer.cat,
        missed_chunks: []
    };

    return unitUpload;
}